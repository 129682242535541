import GenericStore from '../classes/generic-store';
import { getLimitedCandidate } from '../api/limited-candidates-api';
import { CandidateAccountShape } from '../models/candidate';

class CandidateStoreClass extends GenericStore {
  load(id) {
    return this.setState(getLimitedCandidate(id));
  }

  getDataShape() {
    return CandidateAccountShape;
  }
}

export const CandidateStore = new CandidateStoreClass();
