import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  HeaderLink,
  Badge,
  SmallHeader,
} from '@axiom/ui';
import { Link } from 'react-router-dom';
import { Submission } from '@axiom/validation';

import { DateUtil } from '../../utils/date-util';

import { MyEngagementsItemStatus } from './MyEngagementsItemStatus';

export const MyEngagementsCardButton = ({
  submission,
  isUpcomingTab,
}: {
  submission: Submission;
  isUpcomingTab?: boolean;
}): JSX.Element => {
  const daysRemainingOnEngagement = DateUtil.getDuration(
    DateUtil.formatAsDate(new Date()),
    submission.endDate
  );
  const minimumDaysLeftToShowBadge = 45;
  return (
    <Card name="SUBMISSION_BUTTON">
      <CardHeader
        Badge={
          daysRemainingOnEngagement <= minimumDaysLeftToShowBadge &&
          daysRemainingOnEngagement >= 0 &&
          !isUpcomingTab && (
            <Badge
              background="orange"
              name="SUBMISSION_TIME_BADGE"
              flush="right"
            >
              {daysRemainingOnEngagement === 0
                ? 'Ends Today'
                : `Ends in ${daysRemainingOnEngagement} days`}
            </Badge>
          )
        }
      >
        <HeaderLink name="OPPORTUNITY_NAME">
          <SmallHeader>
            <Link
              data-test="LINK"
              to={`../submission/${submission.submissionId}/${
                isUpcomingTab ? 'matches' : 'engagement-details'
              }`}
            >
              {submission.jobName}
            </Link>
          </SmallHeader>
        </HeaderLink>
      </CardHeader>

      <CardSection>
        <MyEngagementsItemStatus
          submission={submission}
          isUpcomingTab={isUpcomingTab}
        />
      </CardSection>
    </Card>
  );
};
