import React, { useCallback } from 'react';
import {
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
  Candidate,
  GroupedExperiences,
  PracticeArea,
  Taxonomy,
} from '@axiom/validation';

import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Gutter } from '../../layout/Gutter/Gutter';
import { BioPdfCandidate } from '../BioPdf/BioPdfCandidate';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { SubmissionPdfDescriptionSingleTalent } from '../SubmissionPdfDescriptionSingleTalent/SubmissionPdfDescriptionSingleTalent';

export type SplitSubmissionPdfTypes = {
  submission: AfcSubmission;
  candidateOpp: AfcSubmissionCandidateOpportunity;
  allGroupedExperiences: GroupedExperiences[][];
  practiceAreas: PracticeArea[];
  clientUrl: string;
  loginUrl: string;
  taxonomy: Taxonomy;
};

export const SplitSubmissionPdf = ({
  submission,
  candidateOpp,
  allGroupedExperiences,
  practiceAreas,
  loginUrl,
  taxonomy,
}: SplitSubmissionPdfTypes) => {
  const getExperiences = useCallback(
    (id: Candidate['id']): GroupedExperiences[] => {
      return (
        allGroupedExperiences.find(ge => ge?.[0]?.candidateId === id) || []
      );
    },
    [allGroupedExperiences, candidateOpp]
  );

  return (
    <div className="k-pdf-export">
      <Layout
        background="panelLight"
        name="SUBMISSION_PDF"
        direction="vertical"
      >
        <LayoutItem>
          <Grid>
            <GridRow>
              <GridColumn>
                <Gutter bottom="32px" key={candidateOpp.candidate.id}>
                  <BioPdfCandidate
                    candidate={candidateOpp.candidate}
                    submissionDescription={candidateOpp.submissionDescription}
                    groupedExperiences={getExperiences(
                      candidateOpp.candidate.id
                    )}
                    submission={submission}
                    practiceAreas={practiceAreas}
                    taxonomy={taxonomy}
                    removeLogo
                  />
                </Gutter>
              </GridColumn>
            </GridRow>
          </Grid>
        </LayoutItem>
        <LayoutItem>
          <SubmissionPdfDescriptionSingleTalent
            loginUrl={loginUrl}
            candidate={candidateOpp.candidate}
          />
        </LayoutItem>
      </Layout>
    </div>
  );
};
