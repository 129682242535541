import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CookieUtil } from '@axiom/ui';

import { PublicEngagementUrlUtil } from '../utils/public-engagement-url-util';

export const MyAxiomAuthRouter = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  if (CookieUtil.isUserExpired() && location.pathname.includes('/matches')) {
    // redirect to public engagement
    return (
      <Navigate
        to={PublicEngagementUrlUtil(location.pathname).toPublic()}
        replace
      />
    );
  }

  return children;
};
