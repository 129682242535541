import React, { useState } from 'react';
import moment from 'moment-timezone';
import { z } from 'zod';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  GridColumn,
  Grid,
  GridRow,
  Paragraph,
  Gutter,
  CalendarTimeZoneUtil,
  Dropdown,
  Form,
  Calendar,
  CalendarPermissionsUtil,
  Layout,
  LayoutItem,
  ImageCircle,
  Checkbox,
  DateUtil,
  CandidateProfileUtil,
  CalendarEventsUtil,
  Banner,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  PermissionImpersonationRoles,
  NO_TIMEZONE,
} from '@axiom/const';
import {
  SubmissionCandidate,
  Calendar as CalendarApiType,
  User,
  CalendarEventType,
  Contact,
  Account,
  CalendarEventSchema,
  AfcSubmission,
} from '@axiom/validation';

import { CalendarApi } from '../../api/calendar-api';
import { CandidateApi } from '../../api/candidate-api';
import {
  TalentInterviewConfirmModal,
  InterviewContactsType,
} from '../TalentInterviewConfirmModal/TalentInterviewConfirmModal';
import { TalentInterviewPromptModal } from '../TalentInterviewPromptModal/TalentInterviewPromptModal';

import { LegendKey } from './TalentInterviewScheduleModalStyles';

export interface InterviewScheduleModalType {
  account: Account;
  submission: AfcSubmission;
  candidate: SubmissionCandidate;
  onClose: () => void;
  onConfirm?: (
    arg: InterviewContactsType,
    scheduledEvent: CalendarEventType
  ) => Promise<unknown>;
  onNoTimeAvailableFlowConfirm?: (
    arg: InterviewContactsType
  ) => Promise<unknown>;
  calendarData: CalendarApiType;
  user: User;
  primaryContactId?: Contact['id'];
}

const NAME = 'CALENDAR_COMPONENT' as const;
const CalendarSchema = z.object({
  [NAME]: CalendarEventSchema.array(),
  TIMEZONE_DROPDOWN: z.string().uuid(),
  NO_AVAILABILITY: z.boolean().default(false),
});
type CalendarType = z.infer<typeof CalendarSchema>;

export const TalentInterviewScheduleModal = ({
  account,
  submission,
  candidate,
  calendarData,
  onClose,
  onConfirm,
  user,
  primaryContactId,
  onNoTimeAvailableFlowConfirm,
}: InterviewScheduleModalType) => {
  const dayWeekMode = {
    slots: 2,
    increments: 30,
  };
  const { userPermissions } = CalendarPermissionsUtil(
    user,
    PermissionImpersonationRoles.client
  );

  const calendarPermissions = userPermissions();
  const { timezone: talentIana } = candidate.calendar || {};
  const guessIana = moment.tz.guess();
  const timezone = CalendarTimeZoneUtil.getZoneFromMomentZone(
    moment.tz(new Date(), talentIana || guessIana)
  );
  const timezoneOptions = CalendarTimeZoneUtil.getDropDownOptions();
  const [confirmModalOpen, setConfirmModalOpen] = useState([]);
  const [showInterviewPromptModal, setShowInterviewPromptModal] =
    useState(false);
  const [formattedPrompt, setFormattedPrompt] = useState('');
  const [calculatedTimezone, setCalculatedTimezone] = useState(timezone);

  const onNextClick = (formData: CalendarType) => {
    if (formData?.NO_AVAILABILITY) {
      setShowInterviewPromptModal(true);
    } else if (formData[NAME].length > 0) {
      setConfirmModalOpen(
        CalendarEventsUtil.clearAndOrganiseEvents(
          calendarPermissions,
          [],
          formData[NAME]
        ).staticEvents
      );
    }
  };

  const calendarEvents = (calendarData?.events || []).reduce(
    (crnt, item) => {
      if (!item.busy && moment().diff(item.startTime, 'days') > -90) {
        // free time up to 90 in the future
        crnt.bgEvents.push(item);
      } else if (
        item.busy &&
        !CalendarEventsUtil.isInThePast(item.endTime, calendarPermissions)
      ) {
        // interviews in the future
        crnt.events.push(item);
      }

      return crnt;
    },
    { events: [], bgEvents: [] } as {
      events: CalendarEventType[];
      bgEvents: CalendarEventType[];
    }
  );

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarData.id),
      CalendarApi.refreshCalendarEvents(calendarData.id),
      CandidateApi.refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = (
    formData: CalendarType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const hasError = CalendarEventsUtil.getFormError(
      formData?.[NAME],
      formData?.TIMEZONE_DROPDOWN,
      formData?.NO_AVAILABILITY
    );
    if (hasError) {
      setFieldError(NAME, hasError);

      return;
    }

    setCalculatedTimezone(
      CalendarTimeZoneUtil.getZoneFromId(
        formData?.TIMEZONE_DROPDOWN ?? timezone.id
      )
    );

    onNextClick(formData);
  };

  return (
    <>
      {confirmModalOpen.length === 0 &&
        !showInterviewPromptModal &&
        !formattedPrompt && (
          <Form
            name="INTERVIEW_AUTO_FORM"
            schema={CalendarSchema}
            initialValues={{
              [NAME]: calendarEvents.events,
              TIMEZONE_DROPDOWN: timezone === NO_TIMEZONE ? null : timezone.id,
              NO_AVAILABILITY: false,
            }}
            onSubmit={handleUpdateOnClick}
          >
            {({ fireSubmit, values }) => {
              return (
                <Modal size="large" name="INTERVIEW_SCHEDULE_MODAL">
                  <ModalHeader
                    onClose={onClose}
                    name="INTERVIEW_SCHEDULE_MODAL_HEADER"
                  >
                    <Layout position="middle" wrap>
                      <LayoutItem rightGutter="16px">
                        <ImageCircle
                          imageName={candidate.calculatedDisplayName}
                          src={CandidateProfileUtil.getProfileImageUri(
                            candidate,
                            PossibleImageSizes.W_100
                          )}
                          size="small"
                          name="TALENT_IMAGE"
                        />
                      </LayoutItem>
                      <LayoutItem fluid name="HEADER_TEXT">
                        {`Interview ${candidate.calculatedFirstName}`}
                      </LayoutItem>
                    </Layout>
                  </ModalHeader>
                  <ModalSection>
                    <Grid>
                      <GridRow>
                        <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                          <Paragraph name="SCHEDULE_GREETING">
                            Hello {user.firstName}. Please select an available
                            30-minute time slot from{' '}
                            {candidate.calculatedFirstName}'s interview
                            calendar.
                          </Paragraph>
                          <Gutter bottom="24px" />
                          <Dropdown
                            label="Time zone"
                            options={timezoneOptions}
                            name="TIMEZONE_DROPDOWN"
                            displayKey="label"
                            valueKey="value"
                          />
                          <Gutter bottom="16px" />
                          <Banner>
                            <Paragraph>
                              If these times don't work, check this box and
                              click Next for more assistance.
                            </Paragraph>
                            <Gutter bottom="16px" />
                            <Checkbox
                              name="NO_AVAILABILITY"
                              displayValue="None of these times work for me."
                            />
                          </Banner>
                          <Gutter bottom="16px" />
                        </GridColumn>
                        <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                          <Calendar
                            name={NAME}
                            permissions={calendarPermissions}
                            backgroundEvents={calendarEvents.bgEvents}
                            dayMode={dayWeekMode}
                            weekMode={dayWeekMode}
                            timezone={
                              values.TIMEZONE_DROPDOWN
                                ? CalendarTimeZoneUtil.getZoneFromId(
                                    values.TIMEZONE_DROPDOWN
                                  ).iana
                                : timezone.iana
                            }
                          />
                          <Gutter bottom="8px" />
                          <Layout position="left middle" horizontalGutter="8px">
                            <LegendKey />
                            <Paragraph name="LEGEND_TEXT">
                              {candidate.calculatedFirstName}'s availability
                            </Paragraph>
                          </Layout>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </ModalSection>
                  <ModalFooter>
                    <Button
                      name="CANCEL_BUTTON"
                      variation="outline"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button name="NEXT_BUTTON" onClick={fireSubmit}>
                      Next
                    </Button>
                  </ModalFooter>
                </Modal>
              );
            }}
          </Form>
        )}

      {!!formattedPrompt && (
        <TalentInterviewConfirmModal
          loggedInUser={user}
          submission={submission}
          candidate={candidate}
          onClose={onClose}
          onConfirm={onNoTimeAvailableFlowConfirm}
          primaryContactId={primaryContactId}
          account={account}
          availabilityNote={formattedPrompt}
        />
      )}

      {confirmModalOpen.length > 0 && (
        <TalentInterviewConfirmModal
          onBackHandler={async () => {
            await refreshData();
            setConfirmModalOpen([]);
          }}
          account={account}
          loggedInUser={user}
          submission={submission}
          candidate={candidate}
          onClose={onClose}
          onConfirm={onConfirm}
          scheduledEvent={confirmModalOpen[0]}
          interviewDate={`${DateUtil.displayBannerTimestamp(
            confirmModalOpen[0].startTime,
            confirmModalOpen[0].endTime,
            calculatedTimezone
          )} ${calculatedTimezone.abbr} ${calculatedTimezone.name}`}
          primaryContactId={primaryContactId}
        />
      )}
      {!!showInterviewPromptModal && (
        <TalentInterviewPromptModal
          candidate={candidate}
          onClose={onClose}
          onBack={() => {
            setShowInterviewPromptModal(false);
          }}
          onNext={(timezoneId, description) => {
            const t = CalendarTimeZoneUtil.getZoneFromId(timezoneId);
            setFormattedPrompt(`${t.abbr} - ${t.name}<br>${description}`);
            setShowInterviewPromptModal(false);
          }}
        />
      )}
    </>
  );
};
