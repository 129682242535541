import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';
import {
  AfcSubmissionCandidateOpportunity,
  Candidate,
} from '@axiom/validation';

type EngagementPromptType = {
  candidateOpp: AfcSubmissionCandidateOpportunity;
  onClose: () => void;
  onConfirm: (candidate: Candidate) => Promise<void>;
};

export const EngagementPrompt = ({
  candidateOpp,
  onClose,
  onConfirm,
}: EngagementPromptType) => {
  return (
    <Modal size="confirm" name="ENGAGE_PROMPT">
      <ModalHeader name="PROMPT_HEADER" onClose={onClose}>
        Confirm your engagement request
      </ModalHeader>
      <ModalSection>
        <Paragraph name="PROMPT_BODY_COPY">
          Confirming your engagement request lets you skip right to the best
          part - engaging {candidateOpp.candidate.calculatedFirstName} for your
          Axiom engagement. We will reach out shortly to get things rolling.
        </Paragraph>
      </ModalSection>
      <ModalFooter stackableOn="mobile">
        <Button name="CANCEL" variation="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          name="CONFIRM"
          onClick={() => onConfirm(candidateOpp.candidate)}
        >
          Engage {candidateOpp.candidate.calculatedFirstName}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
