import React from 'react';
import { Trans } from '@lingui/macro';
import { Flashy } from '@axiom/ui';

import { CandidateAccountShape } from '../../models/candidate';
import { DateUtil } from '../../utils/date-util';

export const MyBenchRollingOff = ({ candidate }) => {
  if (candidate.soonestAccountEngagementEndDate) {
    return (
      <Trans>
        Rolling off on{' '}
        <Flashy bold name="SOONEST_ACC_ENGAGE_END_DATE">
          {DateUtil.displayDateFullYearShortMonth(
            candidate.soonestAccountEngagementEndDate
          )}
        </Flashy>
      </Trans>
    );
  }

  return (
    <Flashy bold name="SOONEST_ACC_ENGAGE_END_DATE">
      <Trans>Not Available</Trans>
    </Flashy>
  );
};

MyBenchRollingOff.propTypes = {
  candidate: CandidateAccountShape.isRequired,
};
