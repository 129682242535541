import { Api } from '@axiom/ui';
import { AccountIntake } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

export type opportunityIntakeResponseType = {
  submissionId: string;
};

class OpportunityIntakeApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  createOpportunityIntake(body: AccountIntake) {
    return super.write<{ data: opportunityIntakeResponseType }>({
      endpoint: `/intake/opportunity`,
      method: 'POST',
      body,
    });
  }
}

export const OpportunityIntakeApi = new OpportunityIntakeApiClass();
