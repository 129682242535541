import React from 'react';
import { bool } from 'prop-types';
import {
  AccordionsItem,
  AccordionsItemHeader,
  Accordions,
  AccordionsItemSection,
  CondensedHeader,
  Gutter,
} from '@axiom/ui';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';

import { TalentSearchFiltersFormSearchBox } from './TalentSearchFiltersFormSearchBox';
import { TalentSearchFiltersFormAdmissionsGroupings } from './TalentSearchFiltersFormAdmissionsGroupings';
import { TalentSearchFiltersFormCheckboxs } from './TalentSearchFiltersFormCheckboxs';

export const TalentSearchFiltersForm = ({ disabled }) => {
  return (
    <FormStateTreatment
      allowOverflow
      name="talent_search_filters_form_treatment"
      formStore={FormTalentSearchFiltersStore}
      showRefetchLoader={false}
      renderLoadedView={({ fireSubmit, formData, form }) => {
        return (
          <>
            <TalentSearchFiltersFormSearchBox
              data={formData}
              onBlur={fireSubmit}
              fireSubmit={fireSubmit}
              form={form}
              disabled={disabled}
            />
            <Gutter bottom="8px" />
            <Accordions minimal>
              {formData.categories.map((item, pIndex) => (
                <AccordionsItem expanded={item.isOpen} key={item.title}>
                  <AccordionsItemHeader
                    name={`${item.title}_${item.selectedCount}`}
                  >
                    <CondensedHeader>
                      {item.title}
                      {item.selectedCount > 0 && ` (${item.selectedCount})`}
                    </CondensedHeader>
                  </AccordionsItemHeader>
                  <AccordionsItemSection>
                    {item.title.toUpperCase() === 'ADMISSION' ? (
                      <TalentSearchFiltersFormAdmissionsGroupings data={item}>
                        {/**
                         * Passing in the prop 'data' below to prevent JS error in the console
                         * The real data is injected via TSFAdmissionsGroupings
                         * */}
                        <TalentSearchFiltersFormCheckboxs
                          data={{}}
                          disabled={disabled}
                          fireSubmit={fireSubmit}
                          parentIndex={pIndex}
                        />
                      </TalentSearchFiltersFormAdmissionsGroupings>
                    ) : (
                      <TalentSearchFiltersFormCheckboxs
                        data={item.items}
                        disabled={disabled}
                        fireSubmit={fireSubmit}
                        parentIndex={pIndex}
                      />
                    )}
                  </AccordionsItemSection>
                </AccordionsItem>
              ))}
            </Accordions>
          </>
        );
      }}
    />
  );
};

TalentSearchFiltersForm.propTypes = {
  disabled: bool,
};

TalentSearchFiltersForm.defaultProps = {
  disabled: false,
};
