import React, { useState, useRef } from 'react';
import { string, node, oneOf, oneOfType, shape } from 'prop-types';
import { Tooltip as KendoToolTip } from '@progress/kendo-react-tooltip';

export const PersistentTooltip = ({
  tooltipContent,
  tooltipStyle,
  children,
  position,
  targetElement,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeCountdown, setCloseCountdown] = useState(null);
  const defaultTarget = useRef(null);

  const cancelTimer = () => {
    clearInterval(closeCountdown);
    setCloseCountdown(null);
  };

  return (
    <KendoToolTip
      anchorElement="target"
      content={() => (
        <span onMouseLeave={() => setIsOpen(false)} onMouseEnter={cancelTimer}>
          {tooltipContent}
        </span>
      )}
      targetElement={targetElement?.current || defaultTarget.current}
      open={isOpen}
      position={position}
      tooltipStyle={tooltipStyle}
    >
      <span
        title={targetElement ? null : 'default'}
        ref={defaultTarget}
        onMouseEnter={() => {
          cancelTimer();
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setCloseCountdown(
            setTimeout(() => {
              setIsOpen(false);
            }, 150)
          );
        }}
      >
        {children}
      </span>
    </KendoToolTip>
  );
};

PersistentTooltip.defaultProps = {
  position: 'top',
  targetElement: null,
  tooltipStyle: null,
};

PersistentTooltip.propTypes = {
  children: node.isRequired,
  position: oneOf(['top', 'right', 'bottom', 'left']),
  targetElement: shape({}),
  tooltipContent: oneOfType([node, string]).isRequired,
  tooltipStyle: shape({}),
};
