import { arrayOf, bool, objectOf, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import {
  deleteFavoriteCandidate,
  getFavoriteCandidates,
  postFavoriteCandidate,
} from '../api/candidate-legacy-api';
import { isLoggedInUser } from '../utils/roles-util';
import { CandidateAccountShape } from '../models/candidate';

const formatState = (favorites = []) => {
  return favorites.reduce(
    (accum, { candidate }) => {
      accum.idMap[candidate.id] = true;
      accum.favorites.push(candidate);
      return accum;
    },
    { idMap: {}, favorites: [] }
  );
};

class FavoriteCandidateStoreClass extends GenericStore {
  load() {
    this.setState(
      getFavoriteCandidates().then(favorites => formatState(favorites))
    );
  }

  getDataShape() {
    return shape({
      idMap: objectOf(bool),
      favorites: arrayOf(shape({ candidate: CandidateAccountShape })),
    });
  }

  toggleFavorite(candidateId, userClient, isFavorited) {
    const isAuthed = isLoggedInUser(userClient);
    if (candidateId) {
      if (isFavorited) {
        this.unfavorite(candidateId, isAuthed);
      } else {
        this.favorite(candidateId, isAuthed);
      }
    }
  }

  favorite(candidateId) {
    this.watchState(postFavoriteCandidate(candidateId)).then(() => {
      this.load();
    });
  }

  unfavorite(candidateId) {
    this.watchState(deleteFavoriteCandidate(candidateId)).then(() => {
      this.load();
    });
  }
}

export const FavoriteCandidateStore = new FavoriteCandidateStoreClass();
