import React from 'react';
import { Page } from '@axiom/ui';
import { UserConst } from '@axiom/const';

import AppContainer from '../components/AppContainer/AppContainer';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { MyEngagementsRedirectClientUser } from '../components/MyEngagements/MyEngagementsRedirectClientUser';
import { InquireWithAxiomModal } from '../components/InquireWithAxiomModal/InquireWithAxiomModal';
import { MyAxiomAuthRouter } from '../routes/MyAxiomAuthRouter';
import { AuthRoute } from '../routes/AuthRoute';

export const MyAxiomPage = () => (
  <Page title="AxiomLaw - Engagements">
    <MyAxiomAuthRouter>
      <AuthRoute
        roles={[
          UserConst.Roles.ClientUser,
          UserConst.Roles.EnvoyAdmin,
          UserConst.Roles.EnvoyUser,
          UserConst.Roles.EnvoySales,
        ]}
      >
        <AppContainer>
          <PageLayout showLoggedUser>
            <InquireWithAxiomModal />
            <MyEngagementsRedirectClientUser />
          </PageLayout>
        </AppContainer>
      </AuthRoute>
    </MyAxiomAuthRouter>
  </Page>
);
