import { CountryCodesConst, CurrencyMap } from '@axiom/const';
import { Candidate, User } from '@axiom/validation';

import { isClientUser, isEnvoyUser } from './roles-util';
import { localStorageUtil } from './local-storage-util';

const getFormattedRate = (
  candidate: Candidate,
  rate: number
): null | string => {
  if (!rate || !candidate.currencyType) {
    return null;
  }
  const currencyFormatFunctions: Record<string, (value: string) => string> = {
    [CurrencyMap.codes.AUD]: (value: string) => `AU$${value}`,
    [CurrencyMap.codes.CAD]: (value: string) => `CA$${value}`,
    [CurrencyMap.codes.CHF]: (value: string) => `${value} CHF`,
    [CurrencyMap.codes.EUR]: (value: string) => `${value} EUR`,
    [CurrencyMap.codes.GBP]: (value: string) => `${value} GBP`,
    [CurrencyMap.codes.HKD]: (value: string) => `HK$${value}`,
    [CurrencyMap.codes.SGD]: (value: string) => `SG$${value}`,
    [CurrencyMap.codes.USD]: (value: string) => `$${value}`,
  };
  return (
    currencyFormatFunctions[candidate.currencyType]?.(
      new Intl.NumberFormat('en-US').format(Math.ceil(rate))
    ) ?? null
  );
};

export type PricingData = {
  pricingAvailable: boolean;
  fullTimeDailyRate?: string;
  adHocHourlyRate?: string;
};

export const PricingUtil = {
  getFormattedRate,
  getPricingData(candidate: Candidate, user: User): PricingData {
    const pricingUnavailable = {
      pricingAvailable: false,
    };
    if (
      !candidate.currencyType ||
      !candidate.addressCountry ||
      !candidate.hoursPerDay ||
      !+candidate.displayLowHourlyRate
    ) {
      return pricingUnavailable;
    }
    const fullTimeDailyRate = `${getFormattedRate(
      candidate,
      +candidate.displayLowHourlyRate * candidate.hoursPerDay
    )}/day`;
    const noAdHocCountry = (
      [
        CountryCodesConst.CountryCodes.DE,
        CountryCodesConst.CountryCodes.CH,
      ] as string[]
    ).includes(candidate.addressCountry);
    if (!noAdHocCountry && !+candidate.displayHighHourlyRate) {
      return pricingUnavailable;
    }
    const adHocHourlyRate = noAdHocCountry
      ? null
      : `${getFormattedRate(candidate, +candidate.displayHighHourlyRate)}/hr`;
    const pricingEnabledForUser =
      (isClientUser(user) && user.contact.canViewPricing) ||
      (isEnvoyUser(user) && localStorageUtil.showPricing().get() === true);
    return {
      fullTimeDailyRate,
      adHocHourlyRate,
      pricingAvailable: !!(fullTimeDailyRate && pricingEnabledForUser),
    };
  },
};
