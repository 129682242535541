/* eslint-disable react/destructuring-assignment */
import { string, arrayOf, oneOfType } from 'prop-types';
import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { InfoIcon } from '@axiom/ui';

import { ComponentShape } from '../../utils/prop-type-util';

import { InfoPopoverCircle, InfoPopoverWrapper } from './InfoPopoverStyles';

export default class InfoPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };
  }

  toggle = () => {
    this.setState(state => ({
      popoverOpen: !state.popoverOpen,
    }));
  };

  render() {
    return (
      <InfoPopoverWrapper color={this.props.color}>
        {/* eslint-disable-next-line react/forbid-elements */}
        <button
          id={this.props.name}
          type="button"
          className="gtm-info-icon"
          data-test="infoPopoverButton"
        >
          <InfoPopoverCircle>
            <InfoIcon hideBorder width={19} height={19} />
          </InfoPopoverCircle>
        </button>
        {this.props.children !== null && (
          <Popover
            placement={this.props.position}
            isOpen={this.state.popoverOpen}
            target={this.props.name}
            toggle={this.toggle}
            trigger="legacy"
          >
            <PopoverBody>{this.props.children}</PopoverBody>
          </Popover>
        )}
      </InfoPopoverWrapper>
    );
  }
}

InfoPopover.propTypes = {
  children: oneOfType([ComponentShape, arrayOf(ComponentShape)]),
  color: string,
  name: string.isRequired,
  position: string,
};

InfoPopover.defaultProps = {
  color: null,
  position: 'left',
  children: null,
};
