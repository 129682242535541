import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ReplaySubject } from 'rxjs';

import { FormError } from '../FormError/FormError';

export const FormGroupError = ({
  propInitializer,
}: {
  propInitializer: ReplaySubject<unknown>;
}) => {
  const {
    errors,
    submitCount,
  }: { errors: Record<string, string>; submitCount: number } =
    useFormikContext();
  const [propNames, setPropNames] = useState([]);

  useEffect(() => {
    const subscribePropNames: string[] = [];
    /**
     * See explanation in FormGroup of why we're using rxjs here.
     */
    const subscription = propInitializer.subscribe(propName => {
      if (
        typeof propName === 'string' &&
        propName &&
        !propNames.includes(propName)
      ) {
        /**
         * Because ReplySubject will fire faster than setState as it replays
         * event queue, and because React batches setStates, this ensures that
         * we're not missing any props from a weird interval update
         */
        subscribePropNames.push(propName);
        setPropNames(subscribePropNames);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [propInitializer]);

  const error = Object.keys(errors).reduce((acc, propName) => {
    if (acc === null && propNames.includes(propName)) {
      return errors[propName];
    }

    return acc;
  }, null);

  return <FormError error={submitCount > 0 && error ? error : null} />;
};
