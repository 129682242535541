import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  ImageCircle,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Radio,
  Textarea,
} from '@axiom/ui';
import { z } from 'zod';
import { SubmissionCandidate } from '@axiom/validation';
import { CandidateImageConst, ClientRequestConst } from '@axiom/const';

import { CandidateUtil } from '../../utils/candidate-util';

type ModalPropTypes = {
  candidate: SubmissionCandidate;
  closeModal: () => void;
  submitModal: (formData: {
    rejectionLossCode: string;
    notes?: string;
  }) => void;
};

const { PossibleImageSizes } = CandidateImageConst;
const { AfcLossReasonLabels } = ClientRequestConst;
const formSchema = z.object({
  rejectionLossCode: z.string(),
  notes: z.string().max(1000).nullable(),
  LOSS_OPTIONS: z.string().optional(),
});
export const EngagementPassModal = ({
  candidate,
  closeModal,
  submitModal,
}: ModalPropTypes) => {
  return (
    <Form
      name="PASS_FORM"
      schema={formSchema}
      onSubmit={data => submitModal(data)}
      initialValues={{
        rejectionLossCode: null,
        notes: null,
      }}
    >
      {({ fireSubmit }) => (
        <Modal name="PASS_MODAL">
          <ModalHeader name="PASS_HEADER" onClose={closeModal}>
            <ImageCircle
              name="HEADER_IMAGE"
              imageName={candidate.calculatedDisplayName}
              src={CandidateUtil.getProfileImageUri(
                candidate,
                PossibleImageSizes.W_56
              )}
            />{' '}
            Passing on {candidate.calculatedDisplayName}?
          </ModalHeader>
          <ModalSection name="PASS_BODY">
            <Grid>
              <GridRow>
                <GridColumn>
                  <Gutter bottom="48px">
                    <Paragraph name="BODY_COPY">
                      No problem. We'll remove {candidate.calculatedDisplayName}{' '}
                      from your matches so Axiom can consider them for other
                      opportunities and you can focus on talent you're more
                      interested in.
                    </Paragraph>
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <Gutter bottom="16px">
                    <FormGroup
                      name="LOSS_OPTIONS"
                      label="Why are you passing on this person?"
                    >
                      {Object.entries(AfcLossReasonLabels)?.map(
                        ([key, value]) => (
                          <Gutter key={key} bottom="8px">
                            <Radio
                              name="rejectionLossCode"
                              option={key}
                              displayValue={value}
                            />
                          </Gutter>
                        )
                      )}
                    </FormGroup>
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <Textarea
                    name="notes"
                    label="Anything else to add that may help to improve future matches?"
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </ModalSection>
          <ModalFooter name="PASS_FOOTER">
            <Button name="CANCEL" variation="outline" onClick={closeModal}>
              Don't Pass
            </Button>
            <Button name="PASS_SUBMIT" onClick={fireSubmit}>
              Yes, Pass
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};
