import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Suspense,
  Loader,
  Tabs,
  TabsItem,
  Gutter,
  ToastUtil,
  CondensedLarge,
  Banner,
  ProgressSteps,
  Visible,
  ProgressStepsItem,
} from '@axiom/ui';
import { AccountConst, CandidatesConst } from '@axiom/const';

import { useCurrentSubmissionData } from '../../hooks/useCurrentSubmissionData';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useEngagementInterviewTalentData } from '../../hooks/useEngagementInterviewTalentData';

const ProgressLabel = {
  matches: 'Matches',
  interviewing: 'Interviewing',
  contracting: 'Contracting',
  engaged: 'Engaged',
} as const;

export const MyEngagementsItem = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { matchesCount, currentSubmission, instantMatches } =
    useCurrentSubmissionData();
  const { interviewCount } = useEngagementInterviewTalentData();
  const { account } = useUserAccountData();
  const onKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p') {
      event.preventDefault();
      const toastName = `PREVENTPRINT`;
      const toasts = ToastUtil.getAll();
      const alreadyOpen = toasts.some(toast => toast.name === toastName);
      if (!alreadyOpen) {
        ToastUtil.add({
          name: toastName,
          type: 'info',
          children: (
            <CondensedLarge>
              To print this page, click “Share” at the top right of the screen,
              and then choose “Download All Matches".
            </CondensedLarge>
          ),
        });
      }
    }
  };

  const progressStatus = () => {
    const candidateOpps =
      currentSubmission?.positions[0]?.candidateOpportunities ?? [];
    const hasEngaged = candidateOpps.find(
      co => co.candidateStatus === CandidatesConst.CandidateStatuses.Engaged
    );
    const hasSelected = candidateOpps.find(
      co => co.candidateStatus === CandidatesConst.CandidateStatuses.Selected
    );
    const hasInterviewing = interviewCount > 0;
    const hasMatches =
      matchesCount > 0 || instantMatches.some(match => match.passed);
    if (hasEngaged) {
      return ProgressLabel.engaged;
    } else if (hasSelected) {
      return ProgressLabel.contracting;
    } else if (hasInterviewing) {
      return ProgressLabel.interviewing;
    } else if (hasMatches) {
      return ProgressLabel.matches;
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const renderStartTab = () => {
    if (location.pathname.includes('interviews')) {
      return 'interviews';
    } else if (location.pathname.includes('engagement-details')) {
      return 'engagement-details';
    }
    return 'matches';
  };

  return (
    <>
      {!currentSubmission?.isQualificationComplete && (
        <Gutter top="16px">
          <Banner name="qualification-banner">
            You’ll be able to take action on these talent matches as soon as we
            confirm a few engagement details.
          </Banner>
        </Gutter>
      )}
      {account.accountType === AccountConst.Types.Direct && (
        <Visible only="largeScreen tablet">
          <Gutter top="16px">
            <ProgressSteps
              direction="horizontal"
              icon="checkmark"
              completedStep={progressStatus()}
              name="engagement-progress-steps"
            >
              <ProgressStepsItem stepName={ProgressLabel.matches}>
                {ProgressLabel.matches}
              </ProgressStepsItem>
              <ProgressStepsItem stepName={ProgressLabel.interviewing}>
                {ProgressLabel.interviewing}
              </ProgressStepsItem>
              <ProgressStepsItem stepName={ProgressLabel.contracting}>
                {ProgressLabel.contracting}
              </ProgressStepsItem>
              <ProgressStepsItem stepName={ProgressLabel.engaged}>
                {ProgressLabel.engaged}
              </ProgressStepsItem>
            </ProgressSteps>
          </Gutter>
        </Visible>
      )}
      <Suspense fallback={<Loader type="bars" />}>
        <Gutter top="16px" />
        <Tabs startTab={renderStartTab()} name="engagement-view">
          <TabsItem
            label="Matches"
            name="matches"
            onClick={() => navigate('./matches')}
            countBadge={matchesCount}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </TabsItem>
          <TabsItem
            label="Interviews"
            name="interviews"
            onClick={() => navigate('./interviews')}
            countBadge={interviewCount}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </TabsItem>
          <TabsItem
            label="Details"
            name="engagement-details"
            onClick={() => navigate('./engagement-details')}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </TabsItem>
        </Tabs>
      </Suspense>
    </>
  );
};
