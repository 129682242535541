import React from 'react';
import { Trans } from '@lingui/macro';
import { WeeklyTimeUnits } from '@axiom/const';
import { Flashy } from '@axiom/ui';

import { CandidateAccountShape } from '../../models/candidate';

export const MyBenchEngagedFor = ({ candidate }) => {
  if (
    typeof candidate.billingUnitsPerWeek === 'number' &&
    candidate.weeklyTimeUnit
  ) {
    if (candidate.weeklyTimeUnit === WeeklyTimeUnits.Daily) {
      return (
        <Trans>
          Engaged for{' '}
          <Flashy bold name="BILLING_UNITS_WEEKLY">
            {candidate.billingUnitsPerWeek}
          </Flashy>{' '}
          <Flashy bold name="WEEKLY_TIME_UNITS">
            days
          </Flashy>{' '}
          per week
        </Trans>
      );
    }
    return (
      <Trans>
        Engaged for{' '}
        <Flashy bold name="BILLING_UNITS_WEEKLY">
          {candidate.billingUnitsPerWeek}
        </Flashy>{' '}
        <Flashy bold name="WEEKLY_TIME_UNITS">
          hours
        </Flashy>{' '}
        per week
      </Trans>
    );
  }
  return (
    <Trans>
      Engaged for{' '}
      <Flashy bold name="WEEKLY_TIME_UNITS_NO_DATA">
        Unknown
      </Flashy>
    </Trans>
  );
};

MyBenchEngagedFor.propTypes = {
  candidate: CandidateAccountShape.isRequired,
};
