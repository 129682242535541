/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Field } from 'react-final-form';
import { bool, func, oneOfType, number, shape, string } from 'prop-types';
import { DataTest } from '@axiom/ui';

import {
  FormElementError,
  isErroring,
} from '../FormElementError/FormElementError';
import { Label } from '../Label/Label';

import RichTextEditorRaw from './RichTextEditorRaw';

export const RichTextEditor = ({
  className,
  label,
  maxLength,
  name,
  onReset,
  placeholder,
  readOnly,
  required,
  showCharsRemainingLabel,
  showResetButton,
  ...rest
}) => (
  <DataTest className={className} value={name}>
    {!!label && <Label id={`${name}-label`}>{label}</Label>}
    <Field allowNull id={name} name={name}>
      {({ input, meta }) => (
        <RichTextEditorRaw
          {...rest}
          ariaLabelledBy={label ? `${name}-label` : null}
          ariaRequired={required}
          dirty={meta.dirty}
          errorMessageNode={<FormElementError finalFormElementMeta={meta} />}
          initialValue={meta.initial}
          invalid={isErroring(meta)}
          maxLength={maxLength}
          onChange={plainText => {
            input.onChange(plainText);
          }}
          onReset={onReset}
          placeholder={placeholder}
          readOnly={readOnly}
          showCharsRemainingLabel={showCharsRemainingLabel}
          showResetButton={showResetButton}
          value={input.value}
        />
      )}
    </Field>
  </DataTest>
);

RichTextEditor.defaultProps = {
  className: null,
  label: null,
  maxLength: RichTextEditorRaw.defaultProps.maxLength,
  onReset: null,
  placeholder: null,
  readOnly: false,
  required: false,
  showButtons: false,
  showCharsRemainingLabel:
    RichTextEditorRaw.defaultProps.showCharsRemainingLabel,
  showResetButton: false,
};

RichTextEditor.propTypes = {
  className: string,
  label: oneOfType([string, shape(Label.propTypes)]),
  maxLength: number,
  name: string.isRequired,
  placeholder: string,
  readOnly: bool,
  required: bool,
  showButtons: bool,
  showCharsRemainingLabel: bool,
  showResetButton: bool,
  onReset: func,
};
