import React from 'react';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Header1,
  Header2,
  PeopleGroupIcon,
} from '@axiom/ui';
import { bool } from 'prop-types';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormDigitalBenchAskUsHowModalStore } from '../../stores/form-digital-bench-ask-us-how-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EnvUtil } from '../../utils/env-util';

import { BCEmptyBenchContainer } from './MyBenchStyles';
import { MyBenchDigitalModal } from './MyBenchDigitalModal';

const getCTAButton = (noFilterData, user, i18n) =>
  noFilterData ? (
    <Button
      name="ACCESS_OUR_NETWORK_BUTTON"
      onClick={() => {
        window.location.href = EnvUtil.clientOurNetworkDetailsUrl;
        return null;
      }}
    >
      <Trans>Access Legal Talent</Trans>
    </Button>
  ) : (
    <>
      <Button
        onClick={() => FormDigitalBenchAskUsHowModalStore.load(user)}
        name="ASK_US_HOW_MODAL_BUTTON"
      >
        <Trans>Ask Us How</Trans>
      </Button>
      <FormStateTreatment
        isModalState
        name="DIGITAL_BENCH_ASK_US_HOW_TREATMENT"
        formStore={FormDigitalBenchAskUsHowModalStore}
        renderLoadedView={({ fireSubmit, fireCancel }) => (
          <MyBenchDigitalModal
            header={i18n._(t`Ask us how to build your Axiom bench`)}
            placeholder={i18n._(
              t`Anything else we should know about your current legal needs to better suggest the right talent for you?`
            )}
            onClose={fireCancel}
            onSubmit={fireSubmit}
            name="DIGITAL_BENCH_ASK_US_HOW"
            fieldName="description"
          />
        )}
      />
    </>
  );

export const MyBenchEmpty = ({ user, noFilterData }) => {
  const { i18n } = useLingui();
  return (
    <Gutter bottom="2rem">
      <BCEmptyBenchContainer vertical="5rem" horizontal="2rem">
        <Grid textAlign="center">
          <GridRow>
            <GridColumn>
              <Gutter bottom="37px">
                <PeopleGroupIcon />
              </Gutter>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <Gutter bottom="16px">
                <Header1 name="EMPTY_BENCH_MESSAGE">
                  {noFilterData ? (
                    <Trans>Can’t find what you’re looking for?</Trans>
                  ) : (
                    <Trans>Looks like your bench is empty.</Trans>
                  )}
                </Header1>
              </Gutter>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <Gutter bottom="30px">
                <Header2 subtle>
                  {noFilterData ? (
                    <Trans>
                      Reach out to your Axiom team or access our network of
                      top-caliber legal talent.
                    </Trans>
                  ) : (
                    <Trans>Fill it with Axiom talent that you trust.</Trans>
                  )}
                </Header2>
              </Gutter>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>{getCTAButton(noFilterData, user, i18n)}</GridColumn>
          </GridRow>
        </Grid>
      </BCEmptyBenchContainer>
    </Gutter>
  );
};

MyBenchEmpty.defaultProps = {
  noFilterData: false,
};

MyBenchEmpty.propTypes = {
  noFilterData: bool,
  user: PreloadedUserStore.getDataShape().isRequired,
};
