import React from 'react';
import { CookieUtil } from '@axiom/ui';
import { Navigate, useLocation } from 'react-router-dom';

import { PublicEngagementUrlUtil } from '../utils/public-engagement-url-util';

export const EngagementAuthRouter = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();

  if (!CookieUtil.isUserExpired()) {
    return (
      <Navigate
        to={PublicEngagementUrlUtil(location.pathname).fromPublic()}
        replace
      />
    );
  }

  return children;
};
