import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Visible,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  CondensedLarge,
  Flashy,
  useApi,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { WindowUtil } from '../../utils/window-util';
import { ExternalCandidateStore } from '../../stores/external-candidate-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { CandidateStore } from '../../stores/candidate-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { FavoriteCandidateStore } from '../../stores/favorite-candidate-store';
import {
  isClientUser,
  isEnvoyUser,
  isLoggedInUser,
} from '../../utils/roles-util';
import { ShareLinkModal } from '../ShareLinkModal/ShareLinkModal';
import { TalentDetails } from '../TalentDetails/TalentDetails';
import { NearestNeighbor } from '../NearestNeighbor/NearestNeighbor';
import { CandidateApi } from '../../api/candidate-api';
import { AccessLegalTalentDetailsViewLoading } from '../AccessLegalTalentDetailsViewLoading/AccessLegalTalentDetailsViewLoading';
import { ShowPricingToggle } from '../ShowPricingToggle/ShowPricingToggle';

const altUrl = '/access-legal-talent';

const Disclaimer = () => (
  <Flashy color="textSubtle">
    <CondensedLarge>
      Axiom is not a law firm and does not provide legal advice. Our clients'
      legal teams supervise the legal work of the Axiom lawyer.
    </CondensedLarge>
  </Flashy>
);

const SinglePageViewComponent = ({
  candidateState,
  user,
  externalCandidateState,
  practiceAreasState,
}) => {
  const [candidateId, setCandidateId] = useState(null);
  const isAuthorized = user && (isClientUser(user) || isEnvoyUser(user));
  const navigate = useNavigate();
  const { id } = useParams();
  const activeCandidateState = isAuthorized
    ? candidateState
    : externalCandidateState;
  const [{ data: groupedExperiences } = { data: [] }] = useApi(
    isAuthorized &&
      candidateId &&
      CandidateApi.readCandidateGroupedExperiences(candidateId)
  );

  useEffect(() => {
    if (id) {
      const idOrPublicRefId = /-\d{6,}$/.test(id) ? id.split('-').pop() : id;

      if (isAuthorized) {
        CandidateStore.load(idOrPublicRefId);
      } else {
        ExternalCandidateStore.load(idOrPublicRefId);
      }
    }
  }, [id]);

  // update the url to the current candidate slug when candidate loads
  useEffect(() => {
    const candidate = activeCandidateState.data;
    const practiceAreas = practiceAreasState.data;

    if (candidate && practiceAreas.length > 0) {
      setCandidateId(candidate.id);
    }
  }, [activeCandidateState]);

  useEffect(() => {
    if (isLoggedInUser(user)) {
      FavoriteCandidateStore.load(user?.id);
    }
  }, [user]);

  const navigateToALT = () => {
    navigate(altUrl);
  };

  return (
    <StoreStateTreatment
      allowOverflow
      name="OUR_NETWORK_DETAILS"
      storeState={[activeCandidateState, practiceAreasState]}
      initialLoader={<AccessLegalTalentDetailsViewLoading />}
      blockingErrorComponent={
        <Modal name="LINK_NOT_WORKING">
          <ModalHeader onClose={navigateToALT}>Link not working?</ModalHeader>
          <ModalSection>
            That profile may no longer be available, or you need to sign in to
            access it. <br />
            Here are a few things you can do:
            <ul>
              <li>
                Sign in and sign up for an Axiom account and try the link again.
              </li>
              <li>
                If someone sent you the link, ask if they can share the profile
                with you a different way.
              </li>
              <li>
                Visit Access Legal Talent to find similar profiles, expore more
                Axiom talent, or chat with an Axiom expert.
              </li>
            </ul>
          </ModalSection>
          <ModalFooter>
            <Button
              variation="outline"
              name="ACCESS_LEGAL_TALENT_BUTTON"
              onClick={navigateToALT}
            >
              Access Legal Talent
            </Button>
            <Button name="SIGN_IN_SIGN_UP_BUTTON" onClick={WindowUtil.toLogin}>
              Sign in or Sign up
            </Button>
          </ModalFooter>
        </Modal>
      }
      renderLoadedView={({ data: [candidate, practiceAreas] }) => {
        return (
          <>
            <ShareLinkModal />
            <Grid stackable>
              <GridRow>
                <GridColumn smallScreenWidth={12} largeScreenWidth={8}>
                  <TalentDetails
                    candidate={candidate}
                    groupedExperiences={groupedExperiences}
                    practiceAreas={practiceAreas}
                    user={user}
                  />
                  <Gutter bottom="16px" />
                  <Visible only="largeScreen">
                    <Disclaimer />
                  </Visible>
                </GridColumn>
                <GridColumn smallScreenWidth={12} largeScreenWidth={4}>
                  {isEnvoyUser(user) && (
                    <Gutter bottom="16px">
                      <ShowPricingToggle />
                    </Gutter>
                  )}
                  {!!isAuthorized && !candidate?.isViewOnly && (
                    <Gutter bottom="16px">
                      <NearestNeighbor candidate={candidate} user={user} />
                    </Gutter>
                  )}
                  <Visible only="smallScreen">
                    <Disclaimer />
                  </Visible>
                </GridColumn>
              </GridRow>
            </Grid>
          </>
        );
      }}
    />
  );
};

SinglePageViewComponent.propTypes = {
  candidateState: CandidateStore.getStateShape().isRequired,
  externalCandidateState: ExternalCandidateStore.getStateShape().isRequired,
  practiceAreasState: PreloadedPracticeAreasStore.getStateShape().isRequired,
  user: PreloadedUserStore.getDataShape(),
};

SinglePageViewComponent.defaultProps = {
  user: null,
};

export const SinglePageView = connect(state => ({
  candidateState: CandidateStore.select(state),
  user: PreloadedUserStore.selectData(state),
  externalCandidateState: ExternalCandidateStore.select(state),
  practiceAreasState: PreloadedPracticeAreasStore.select(state),
}))(SinglePageViewComponent);
