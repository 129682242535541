import React, { useEffect } from 'react';
import { CookieUtil, Loader, Page } from '@axiom/ui';
import { connect } from 'react-redux';
import { User } from '@axiom/validation';

import AppContainer from '../components/AppContainer/AppContainer';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { SinglePageView } from '../components/SinglePageView/SinglePageView';
import { PreloadedUserStore } from '../stores/preloaded-user-store';
import { InquireWithAxiomModal } from '../components/InquireWithAxiomModal/InquireWithAxiomModal';
import { ExternalSearchReachOutModal } from '../components/ExternalSearchReachOutModal/ExternalSearchReachOutModal';
import { PreloadedPracticeAreasStore } from '../stores/preloaded-practice-areas-store';

export const TalentDetailPage = connect(state => ({
  user: PreloadedUserStore.selectData(state),
}))(({ user }: { user: User }) => {
  const shouldFetchUser = !user && CookieUtil.decodeUserCookie();
  useEffect(() => {
    if (shouldFetchUser) {
      PreloadedUserStore.load();
    }
  }, []);

  useEffect(() => {
    PreloadedPracticeAreasStore.load();
  }, []);

  if (shouldFetchUser) {
    return <Loader />;
  }

  return (
    <Page title="AxiomLaw - Talent Detail">
      <AppContainer>
        <PageLayout showLoggedUser>
          <InquireWithAxiomModal />
          <ExternalSearchReachOutModal />
          <SinglePageView />
        </PageLayout>
      </AppContainer>
    </Page>
  );
});
