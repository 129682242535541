import React from 'react';
import { Badge, Layout } from '@axiom/ui';
import { Candidate, SearchCandidate } from '@axiom/validation';
import { CandidatesConst } from '@axiom/const';

export const TalentCarouselBadge = ({
  candidate,
  isSavedCarousel,
}: {
  candidate: Candidate | SearchCandidate;
  isSavedCarousel?: boolean;
}) => {
  const availabilityStatus =
    CandidatesConst.AvailabilityStatuses[
      candidate.availabilityStatus as keyof typeof CandidatesConst.AvailabilityStatuses
    ];

  switch (candidate.availabilityStatus) {
    case CandidatesConst.AvailabilityStatusKeys.AvailableNow: {
      return (
        <Layout inline>
          <Badge name="AVAILABLE_NOW_BADGE" background="light orange">
            {availabilityStatus}
          </Badge>
        </Layout>
      );
    }

    case CandidatesConst.AvailabilityStatusKeys.AvailableSoon: {
      return (
        <Layout inline>
          <Badge
            name="AVAILABLE_SOON_BADGE"
            background={isSavedCarousel ? 'gray' : 'light blue'}
          >
            {availabilityStatus}
          </Badge>
        </Layout>
      );
    }

    default: {
      return null;
    }
  }
};
