import { useApiWithErrors, ApiError, GoneError } from '@axiom/ui';
import {
  Account,
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
} from '@axiom/validation';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsApi } from '../api/account-submissions-api';
import { SubmissionsUtil } from '../utils/submissions-util';

interface Args {
  accountId?: Account['id'];
  submissionId?: AfcSubmission['id'];
}

export const useEngagementInterviewTalentData = ({
  accountId: argAccountId,
  submissionId: argSubmissionId,
}: Args = {}) => {
  const { submissionId: urlSubmissionId, accountId: urlAccountId } =
    useParams();
  const accountId = urlAccountId ?? argAccountId;
  const submissionId = urlSubmissionId ?? argSubmissionId;
  const [response] = useApiWithErrors(
    accountId &&
      submissionId &&
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId
      )
  );
  let invalidSubmissionRequest = false;
  const submissionData: AfcSubmission = response?.data;
  if (response instanceof ApiError) {
    if (response instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw response;
    }
  }

  const currentInterviewSubmission: AfcSubmission =
    SubmissionsUtil.getInterviewingCandidates(submissionData);
  const candidateOpps: AfcSubmissionCandidateOpportunity[] =
    currentInterviewSubmission?.positions?.flatMap(
      position => position.candidateOpportunities
    );

  const interviewCount = candidateOpps?.length ?? 0;
  return {
    candidateOpps,
    currentInterviewSubmission,
    interviewCount,
    invalidSubmissionRequest,
  };
};
