import React from 'react';
import { DataTest, Gutter } from '@axiom/ui';
import { bool, func, number, oneOf, string } from 'prop-types';

import { itemShape } from '../../models/search-filters';
import { Checkbox } from '../FormElements/Checkbox/Checkbox';

export const TalentSearchFiltersFormTemplate = ({
  item,
  index,
  parentIndex,
  onChange,
  disabled,
  area,
  groupingName,
}) => {
  const name = `categories[${parentIndex}].items${
    groupingName ? `.${groupingName}` : ''
  }.${area}[${index}].checked`;

  return (
    <Gutter bottom="8px">
      <DataTest value="CHECKBOX_CONTAINER">
        <DataTest value={JSON.stringify(item.id)}>
          <Checkbox
            name={name}
            label={item.name}
            verticalAlign="middle"
            disabled={disabled}
            onChange={onChange}
          />
        </DataTest>
      </DataTest>
    </Gutter>
  );
};

TalentSearchFiltersFormTemplate.defaultProps = {
  groupingName: null,
};
TalentSearchFiltersFormTemplate.propTypes = {
  area: oneOf(['alwaysShow', 'showHide']).isRequired,
  disabled: bool.isRequired,
  groupingName: string,
  index: number.isRequired,
  item: itemShape.isRequired,
  parentIndex: number.isRequired,
  onChange: func.isRequired,
};
