import React from 'react';
import { shape, oneOf } from 'prop-types';
import { IconButton, Pagination } from '@axiom/ui';

import { isLoggedInUser } from '../../utils/roles-util';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

export const TalentSearchPagination = ({ storeData, location, user }) => {
  const { currentPage, pageCount } = storeData.meta.serverMeta || {};

  const isUserLoggedIn = isLoggedInUser(user);

  const getPaginationLink = incremental => {
    const queryParams = window.location.search || '';
    let pageNumber = currentPage + incremental;
    pageNumber =
      (pageNumber < 1 && 1) ||
      (pageNumber > pageCount && pageCount) ||
      pageNumber;
    return `/access-legal-talent/page/${pageNumber}${queryParams}`;
  };

  return (
    <Pagination
      name="ALT_SEARCH_PAGINATION"
      currentPage={currentPage}
      nextButton={
        <IconButton
          className={`gtm-talent-search-${location}-next-${
            isUserLoggedIn ? 'authed' : 'unauthed'
          }`}
          icon="arrow-right"
          name="pagination-next-button"
          pattern="secondary"
          to={getPaginationLink(1)}
          variation="minimal"
        />
      }
      prevButton={
        <IconButton
          className={`gtm-talent-search-${location}-prev-${
            isUserLoggedIn ? 'authed' : 'unauthed'
          }`}
          icon="arrow-left"
          name="pagination-prev-button"
          pattern="secondary"
          to={getPaginationLink(-1)}
          variation="minimal"
        />
      }
      totalPages={pageCount}
    />
  );
};

TalentSearchPagination.defaultProps = {};

TalentSearchPagination.propTypes = {
  location: oneOf(['top', 'bottom']).isRequired,
  storeData: shape({}).isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};
