import React from 'react';
import {
  Brief,
  CandidateHighlightExperienceSummary,
  SmallHeader,
  Gutter,
  CandidateHighlightExperienceDetails,
  CandidateDescription,
  TalentExperiences,
  TalentEducation,
  TalentCertifications,
  TalentQualifications,
  TalentLanguages,
} from '@axiom/ui';
import {
  PracticeArea,
  GroupedExperiences,
  Taxonomy,
  AfcSubmission,
  AfcSubmissionCandidate,
} from '@axiom/validation';
import { SubmissionCandidateModel } from '@axiom/ui-models';

export const MyEngagementsInterviewTalentProfile = ({
  candidate,
  groupedExperiences,
  submission,
  practiceAreas,
  taxonomy,
}: {
  candidate: AfcSubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  submission: AfcSubmission;
  practiceAreas: PracticeArea[];
  taxonomy: Taxonomy;
}) => {
  const candidateDescription = new SubmissionCandidateModel({
    candidate,
  }).calculatedDescription();

  const numberOfHighlightDetails = [
    candidate?.industries?.length > 0,
    candidate?.lawFirms?.length > 0,
    candidate?.practiceArea?.id,
    candidate?.addressCity ||
      candidate?.addressState ||
      candidate?.addressCountry,
  ].filter(Boolean).length;

  return (
    <Brief
      briefMaxChildren={1}
      pattern="secondary"
      name="INTERVIEW_CANDIDATE_BRIEF"
    >
      <CandidateHighlightExperienceSummary
        condensed
        candidate={candidate}
        submission={submission}
      />
      {!!candidateDescription && (
        <Gutter vertical="16px" name="INTERVIEW_CANDIDATE_DESCRIPTION">
          <CandidateDescription
            description={candidateDescription}
            calculatedFirstName={candidate?.calculatedFirstName}
          />
        </Gutter>
      )}
      {numberOfHighlightDetails > 0 && (
        <>
          <SmallHeader>Highlights</SmallHeader>
          <Gutter bottom="16px" />
          <CandidateHighlightExperienceDetails
            candidate={candidate}
            practiceAreas={practiceAreas}
            taxonomy={taxonomy}
          />
        </>
      )}
      {groupedExperiences.length > 0 && (
        <>
          <Gutter vertical="16px">
            <SmallHeader name="INTERVIEW_EXPERIENCE_HEADER">
              Experience
            </SmallHeader>
          </Gutter>
          <TalentExperiences
            groupedExperiences={groupedExperiences}
            practiceAreas={practiceAreas}
          />
        </>
      )}
      {candidate.degrees.length > 0 && (
        <>
          <Gutter vertical="16px">
            <SmallHeader name="INTERVIEW_EDUCATION_HEADER">
              Education
            </SmallHeader>
          </Gutter>
          <TalentEducation candidate={candidate} />
        </>
      )}
      {candidate.certifications.length > 0 && (
        <>
          <Gutter vertical="16px">
            <SmallHeader name="INTERVIEW_CERTIFICATIONS_HEADER">
              Accomplishments
            </SmallHeader>
          </Gutter>
          <TalentCertifications candidate={candidate} />
          <Gutter bottom="16px" />
          <TalentQualifications candidate={candidate} />
        </>
      )}
      {candidate.languages.length > 0 && (
        <>
          <Gutter vertical="16px">
            <SmallHeader name="INTERVIEW_LANGUAGES_HEADER">
              Languages
            </SmallHeader>
          </Gutter>
          <TalentLanguages candidate={candidate} />
        </>
      )}
    </Brief>
  );
};
