import { Api } from '@axiom/ui';
import { ContactsOpportunity, Submission } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class OpportunityApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  updateConfidentiality(
    oppId: Submission['id'],
    body: Record<string, unknown>
  ) {
    return super.write<{ data: Submission }>({
      endpoint: `/opportunities/${oppId}/sharing/confidentiality`,
      method: 'PATCH',
      body,
    });
  }

  deleteContactOpp(
    oppId: Submission['id'],
    contactId: ContactsOpportunity['contactId']
  ) {
    return super.write<{ data: Submission }>({
      endpoint: `/opportunities/${oppId}/contacts/${contactId}`,
      method: 'DELETE',
    });
  }
}

export const OpportunityApi = new OpportunityApiClass();
