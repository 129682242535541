import { AfcSubmissionCandidate, AfcSubmission } from '@axiom/validation';
import { CurrencyCodeKeyTypes, BarredLocationsConst } from '@axiom/const';
import { CurrencyUtil } from '@axiom/utils';

import { CandidateRateUtil } from './candidate-rate-util';

export const CandidateHighlightsUtil = {
  calculateNumberOfHighlights(
    candidate: AfcSubmissionCandidate,
    submission?: AfcSubmission
  ) {
    return [
      submission &&
        CandidateRateUtil.formatCandidateRate(
          candidate.id,
          CurrencyUtil.convertToSymbol(
            submission?.currency as CurrencyCodeKeyTypes
          ),
          submission
        ) !== '--' &&
        CandidateRateUtil.formatCandidateRate(
          candidate.id,
          CurrencyUtil.convertToSymbol(
            submission?.currency as CurrencyCodeKeyTypes
          ),
          submission
        ),
      candidate?.lawDegreeSchools?.length > 0,
      candidate?.yearsOfExperience && candidate?.yearsOfExperience >= 0,
      candidate?.barredLocations?.filter(
        admission => admission.name !== BarredLocationsConst.Names.NotAdmitted
      ).length > 0,
      candidate?.industries?.length > 0,
      candidate?.lawFirms?.length > 0,
      candidate?.practiceArea?.id,
      candidate?.addressCity ||
        candidate?.addressState ||
        candidate?.addressCountry,
    ].filter(Boolean).length;
  },
  calculateNumberOfSummaryHighlights(candidate: AfcSubmissionCandidate) {
    return [
      candidate?.yearsOfExperience && candidate?.yearsOfExperience >= 0,
      candidate?.lawDegreeSchools?.length > 0,
      candidate?.barredLocations?.filter(
        admission => admission.name !== BarredLocationsConst.Names.NotAdmitted
      ).length > 0,
    ].filter(Boolean).length;
  },
};
