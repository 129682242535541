import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountConst } from '@axiom/const';
import { useApi } from '@axiom/ui';
import { Account } from '@axiom/validation';

import { DirectEngagementsForm } from '../components/DirectEngagementsForm/DirectEngagementsForm';
import { AccountLegacyApi } from '../api/account-legacy-api';

const isDirectAccount = (account: Account) =>
  account?.accountType === AccountConst.Types.Direct;

export const DirectClientRoute = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const [{ data: account }] = useApi(AccountLegacyApi.getAccount(accountId));

  useEffect(() => {
    if (!isDirectAccount(account)) {
      navigate('../engagements');
    }
  }, [accountId]);

  return (
    isDirectAccount(account) && <DirectEngagementsForm accountId={accountId} />
  );
};
