import { Api } from '@axiom/ui';
import { AfcSubmission, Submission } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AccountSubmissionsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAccountSubmissionDetails(
    accountId: Submission['accountId'],
    submissionId: AfcSubmission['submissionId']
  ) {
    return super.read<{ data: AfcSubmission }>({
      endpoint: `/accounts/${accountId}/submissions/${submissionId}`,
      method: 'GET',
    });
  }

  refreshAccountSubmissionDetails(
    accountId: Submission['accountId'],
    submissionId: AfcSubmission['submissionId']
  ) {
    return super.invalidate(
      `/accounts/${accountId}/submissions/${submissionId}`
    );
  }
}

export const AccountSubmissionsApi = new AccountSubmissionsApiClass();
