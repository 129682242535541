import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import {
  Grid,
  GridRow,
  ShareBenchIcon,
  GridColumn,
  Button,
  CardSection,
  Card,
  LargeHeader,
  SmallHeader,
  Paragraph,
  CardFooter,
  Gutter,
} from '@axiom/ui';
import { connect } from 'react-redux';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { EndOfTalentSearchStore } from '../../stores/end-of-talent-search-store';
import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';

export const TalentSearchEmptyComponent = ({
  handleReachOutClick,
  handleClearFiltersClick,
  isHomeTabSelected,
  onClick,
  activeFilters,
  endOfTalentState,
}) => {
  const getStrings = (isHome, count) => {
    const data = {};
    if (isHome) {
      data.name = 'TALENT_SEARCH_EMPTY';
      data.title =
        'Looking for legal talent with unique or high-demand qualifications?';
      data.subtitle = `They can be hard to find but we've got 'em. Try adjusting the filters to broaden your search.`;
      data.body = `If that doesn't work, please reach out to Axiom and let us help. We can peek at profiles that aren't shown here yet and find your one-of-a-kind legal talent before anyone else does.`;

      if (count > 0) {
        data.title = 'No profiles? No problem.';
        data.subtitle = '';
        data.body = (
          <>
            We have <span data-test="OPT_OUT_COUNT">{count}</span> profile(s)
            for Axiom talent who are available and match your filters but aren’t
            shown here yet. Please reach out if you’d like to find out more
            about them.
          </>
        );
      }
    } else {
      data.name = 'TALENT_FAVORITES_EMPTY';
      data.title = `Looks like you don't have any saved talent`;
      data.subtitle = '';
      data.body = `Saved talent profiles that you're interested in so you can easily find them later.`;
    }

    return data;
  };

  useEffect(() => {
    EndOfTalentSearchStore.load(activeFilters);
  }, [activeFilters]);

  return (
    <StoreStateTreatment
      name="TALENT_SEARCH_EMPTY"
      allowOverflow
      storeState={endOfTalentState}
      showRefetchLoader={false}
      renderLoadedView={({ data: { endOfTalentCount } }) => {
        const { title, subtitle, body } = getStrings(
          isHomeTabSelected,
          endOfTalentCount
        );

        return (
          <Card>
            <CardSection divider>
              <Grid>
                <GridRow>
                  <GridColumn
                    largeScreenWidth={2}
                    tabletWidth={3}
                    mobileWidth={12}
                  >
                    <ShareBenchIcon width={110} height={110} />
                  </GridColumn>
                  <GridColumn
                    largeScreenWidth={10}
                    tabletWidth={9}
                    mobileWidth={12}
                  >
                    <Gutter bottom="16px">
                      <LargeHeader name="empty_talent_header">
                        {title}
                      </LargeHeader>
                    </Gutter>
                    <Gutter bottom="16px">
                      <SmallHeader>{subtitle}</SmallHeader>
                    </Gutter>
                    <Paragraph>{body}</Paragraph>
                  </GridColumn>
                </GridRow>
              </Grid>
            </CardSection>
            <CardFooter>
              {!!isHomeTabSelected && (
                <>
                  <Button
                    name="TALENT_SEARCH_EMPTY_CLEAR_FILTERS"
                    variation="outline"
                    onClick={handleClearFiltersClick}
                  >
                    Clear Filters
                  </Button>

                  <Button
                    name="TALENT_SEARCH_EMPTY_REACH_OUT"
                    onClick={handleReachOutClick}
                  >
                    Reach Out
                  </Button>
                </>
              )}
              {!isHomeTabSelected && (
                <Button name="ADD_FAVORITES" onClick={() => onClick('home')}>
                  Add Saved Talent
                </Button>
              )}
            </CardFooter>
          </Card>
        );
      }}
    />
  );
};

TalentSearchEmptyComponent.propTypes = {
  activeFilters: FormTalentSearchFiltersStore.getSelectedFiltersShape(),
  endOfTalentState: EndOfTalentSearchStore.getStateShape().isRequired,
  handleClearFiltersClick: func,
  handleReachOutClick: func,
  isHomeTabSelected: bool,
  onClick: func.isRequired,
};

TalentSearchEmptyComponent.defaultProps = {
  handleClearFiltersClick: () => null,
  handleReachOutClick: () => null,
  isHomeTabSelected: false,
  activeFilters: {},
};
export const TalentSearchEmpty = connect(state => ({
  endOfTalentState: EndOfTalentSearchStore.select(state),
}))(TalentSearchEmptyComponent);
