import React from 'react';
import { bool, func, oneOfType, shape, string } from 'prop-types';
import { RadioIcon } from '@axiom/ui';

import { formatDataTestId } from '../../../utils/data-test-util';
import { Label } from '../Label/Label';

import {
  RadioButtonContainer,
  RadioButtonIconWrapper,
  RadioButtonLabel,
} from './RadioButtonStyles';

export const RawRadioButton = ({
  checked,
  className,
  label,
  name,
  domID,
  onChange,
  onFocus,
  onBlur,
  value,
  disabled,
}) => {
  return (
    <RadioButtonContainer
      className={className}
      data-test={formatDataTestId(`'RADIO_'${name}_${value}`)}
      onClick={e => {
        if (!disabled) {
          e.stopPropagation();
          onChange(value);
        }
      }}
    >
      <RadioButtonIconWrapper data-test="CLICKABLE_AREA" highlighted={checked}>
        <input
          data-test="RADIO"
          checked={checked}
          id={domID}
          value={value}
          className="sr-only"
          disabled={disabled}
          type="radio"
          onChange={onChange}
          onBlur={e => !disabled && onBlur(e)}
          onFocus={e => !disabled && onFocus(e)}
          name={name}
        />
        <RadioIcon checked={checked} disabled={disabled} />
      </RadioButtonIconWrapper>
      <span>
        <RadioButtonLabel htmlFor={domID} checked={checked} disabled={disabled}>
          {typeof label === 'string' ? <Label>{label}</Label> : label}
        </RadioButtonLabel>
      </span>
    </RadioButtonContainer>
  );
};

RawRadioButton.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
  domID: string.isRequired,
  label: oneOfType([string, shape(Label.propTypes)]),
  name: string,
  value: oneOfType([string, bool]).isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
};

RawRadioButton.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  label: null,
  name: null,
  onChange: () => false,
  onBlur: () => {},
  onFocus: () => {},
};

export const RadioButton = ({
  domID,
  input,
  label,
  inputOnChange,
  onBlur,
  onFocus,
  className,
}) => {
  const handleChange = value => {
    input.onChange(value);
    inputOnChange(value);
  };

  return (
    <RawRadioButton
      className={className}
      label={label}
      name={input.name}
      domID={domID}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={input.value}
      checked={input.checked}
    />
  );
};

RadioButton.propTypes = {
  className: string,
  domID: string.isRequired,
  input: shape({}).isRequired,
  inputOnChange: func,
  label: string,
  onBlur: func,
  onFocus: func,
};

RadioButton.defaultProps = {
  label: null,
  inputOnChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  className: '',
};
