import { UserConst } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { userClientModelShape } from '../models/user';
import { getLoggedUser } from '../api/user-api';
import { getAccount } from '../api/account-legacy-api';
import { isEnvoyUser } from '../utils/roles-util';
import { WindowUtil } from '../utils/window-util';

class UserStoreClass extends GenericStore {
  fetch(accountId) {
    return getLoggedUser({
      expand: [UserConst.Expansions.contactAccountInfo],
    }).then(async clientUser => {
      /**
       * Envoy Users won't have a contact which means they won't have an array of
       * accounts so with this if check, we fill in the structure as if they're
       * a ClientUser
       */
      if (accountId && clientUser && isEnvoyUser(clientUser)) {
        const account = await getAccount(accountId);
        if (account) {
          clientUser.contact = {
            firstName: clientUser.firstName,
            lastName: clientUser.lastName,
            email: clientUser.email,
            accounts: [account],
          };
        }
      }

      WindowUtil.initializePendo({ user: clientUser });

      return clientUser;
    });
  }

  load(accountId) {
    return this.setState(this.fetch(accountId));
  }

  getDataShape() {
    return userClientModelShape;
  }

  selectData(state) {
    return this.select(state)?.data;
  }
}

export const PreloadedUserStore = new UserStoreClass();
