import React from 'react';
import { Page } from '@axiom/ui';
import { Outlet } from 'react-router-dom';

import AppContainer from '../components/AppContainer/AppContainer';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { EngagementAuthRouter } from '../routes/EngagementAuthRouter';

export const EngagementPage = () => (
  <Page title="AxiomLaw - Engagements">
    <EngagementAuthRouter>
      <AppContainer>
        <PageLayout>
          <div data-test="PUBLIC_ENGAGEMENT">
            <Outlet />
          </div>
        </PageLayout>
      </AppContainer>
    </EngagementAuthRouter>
  </Page>
);
