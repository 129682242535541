import React from 'react';
import { Page } from '@axiom/ui';
import { Outlet } from 'react-router-dom';
import { UserConst } from '@axiom/const';

import AppContainer from '../components/AppContainer/AppContainer';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { InquireWithAxiomModal } from '../components/InquireWithAxiomModal/InquireWithAxiomModal';
import { ExternalSearchReachOutModal } from '../components/ExternalSearchReachOutModal/ExternalSearchReachOutModal';
import { AccessLegalTalentProvider } from '../providers/AccessLegalTalentProvider';
import { AuthRoute } from '../routes/AuthRoute';

export const AccessLegalTalentPage = () => {
  return (
    <Page title="AxiomLaw - Access Legal Talent">
      <AuthRoute
        roles={[
          UserConst.Roles.ClientUser,
          UserConst.Roles.EnvoyAdmin,
          UserConst.Roles.EnvoyUser,
          UserConst.Roles.EnvoySales,
        ]}
      >
        <AppContainer>
          <PageLayout showLoggedUser>
            <AccessLegalTalentProvider>
              <InquireWithAxiomModal />
              <ExternalSearchReachOutModal />
              <Outlet />
            </AccessLegalTalentProvider>
          </PageLayout>
        </AppContainer>
      </AuthRoute>
    </Page>
  );
};
