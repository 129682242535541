import React from 'react';
import { Page } from '@axiom/ui';
import { Outlet } from 'react-router-dom';
import { UserConst } from '@axiom/const';

import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { AuthRoute } from '../routes/AuthRoute';
import AppContainer from '../components/AppContainer/AppContainer';

export const BenchPage = () => {
  return (
    <Page title="AxiomLaw - Bench">
      <AuthRoute
        roles={[
          UserConst.Roles.ClientUser,
          UserConst.Roles.EnvoyAdmin,
          UserConst.Roles.EnvoyUser,
          UserConst.Roles.EnvoySales,
        ]}
      >
        <AppContainer>
          <PageLayout showLoggedUser name="BENCH">
            <Outlet />
          </PageLayout>
        </AppContainer>
      </AuthRoute>
    </Page>
  );
};
