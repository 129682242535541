import { Api } from '@axiom/ui';
import { InstantMatch, Opportunity } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class InstantMatchesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  getInstantMatches(submissionId: Opportunity['submissionId']) {
    return super.read<{ data: Array<InstantMatch> }>({
      endpoint: `/instant/matches/search/${submissionId}`,
      method: 'POST',
    });
  }

  readInstantMatches(submissionId: Opportunity['submissionId']) {
    return super.read<{ data: Array<InstantMatch> }>({
      endpoint: `/instant/matches/submissions/${submissionId}`,
      method: 'GET',
    });
  }

  updateInstantMatchPassed(body: InstantMatch) {
    return super.write<{ data: null }>({
      endpoint: `/instant/matches/pass`,
      body,
      method: 'PUT',
    });
  }

  createSubmittedCandidateOppFromInstantMatch(body: InstantMatch) {
    return super.write<{ data: null }>({
      endpoint: `/instant/matches/candidate-opportunity`,
      body,
      method: 'POST',
    });
  }

  refreshInstantMatches(submissionId: Opportunity['submissionId']) {
    return super.invalidate(`/instant/matches/submissions/${submissionId}`);
  }
}

export const InstantMatchesApi = new InstantMatchesApiClass();
