import React from 'react';
import { shape, string, bool } from 'prop-types';
import styled from 'styled-components';

import { PALETTE } from '../../../themes/constants';
import { formatDataTestId } from '../../../utils/data-test-util';

const ErrorWrapper = styled.div`
  color: ${PALETTE.theme.danger};
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
`;

export const isErroring = finalFormElementMeta =>
  !!(
    (finalFormElementMeta.error || finalFormElementMeta.submitError) &&
    finalFormElementMeta.touched
  );

export const FormElementError = ({ className, finalFormElementMeta, id }) => (
  <ErrorWrapper className={className}>
    {!!(finalFormElementMeta.error || finalFormElementMeta.submitError) &&
      !!finalFormElementMeta.touched && (
        <span data-test={formatDataTestId('formError')} id={id}>
          {finalFormElementMeta.error || finalFormElementMeta.submitError}
        </span>
      )}
  </ErrorWrapper>
);

FormElementError.defaultProps = {
  className: null,
  id: null,
};

FormElementError.propTypes = {
  className: string,
  finalFormElementMeta: shape({
    touched: bool,
    error: string,
    submitError: string,
  }).isRequired,
  id: string,
};
