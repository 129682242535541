import React from 'react';
import { string, oneOf } from 'prop-types';

import { LoadingBarStyled } from './LoadingBarStyles';

export const LoadingBar = ({ size, width, height }) => {
  let finalWidth;
  let finalHeight;

  // eslint-disable-next-line default-case
  switch (size) {
    case 'text': {
      finalWidth = '100%';
      finalHeight = '1.2rem';
      break;
    }
    case 'label': {
      finalWidth = '20%';
      finalHeight = '1.375rem';
      break;
    }
    case 'header': {
      finalWidth = '100%';
      finalHeight = '2.125rem';
      break;
    }
  }

  finalWidth = width ?? finalWidth;
  finalHeight = height ?? finalHeight;

  return <LoadingBarStyled width={finalWidth} height={finalHeight} />;
};

LoadingBar.propTypes = {
  height: string,
  size: oneOf(['header', 'label', 'text']),
  width: string,
};

LoadingBar.defaultProps = {
  size: 'text',
  width: null,
  height: null,
};
