import React from 'react';
import { z } from 'zod';
import { Card, CardSection, Form, Toggle } from '@axiom/ui';

import { localStorageUtil } from '../../utils/local-storage-util';

export const ShowPricingToggle = () => {
  return (
    <Card name="PENDO_SHOW_PRICING_TOGGLE">
      <CardSection>
        <Form
          schema={z.object({
            showPricing: z.boolean().nullish(),
          })}
          initialValues={{
            showPricing: localStorageUtil.showPricing().get(),
          }}
          submitOnChange
          onSubmit={async changedData => {
            localStorageUtil.showPricing().set(!!changedData.showPricing);
            window.location.reload();
          }}
        >
          {() => <Toggle name="showPricing" label="Show Pricing" />}
        </Form>
      </CardSection>
    </Card>
  );
};
