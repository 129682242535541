import React from 'react';
import {
  Candidate,
  GroupedExperiences,
  PracticeArea,
  SubmissionCandidate,
  User,
} from '@axiom/validation';

import { BioPdf } from '../BioPdf/BioPdf';
import { BioView } from '../BioView/BioView';

export type TalentBiosType = {
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  onNavigate?: (hideHighlights?: boolean, hideLocation?: boolean) => void;
  isAnonymous?: boolean;
  download?: boolean;
  clientUrl: string;
  user?: User;
  hideHighlights?: boolean;
  hideLocation?: boolean;
};

const noopFn = () => {};
export const TalentBios = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  onNavigate = noopFn,
  isAnonymous = false,
  download = false,
  clientUrl,
  user,
  hideHighlights = false,
  hideLocation = false,
}: TalentBiosType): JSX.Element | null => {
  return download ? (
    <BioPdf
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      isAnonymous={isAnonymous}
      clientUrl={clientUrl}
      hideHighlights={hideHighlights}
      hideLocation={hideLocation}
    />
  ) : (
    <BioView
      user={user}
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      isAnonymous={isAnonymous}
      onNavigate={onNavigate}
    />
  );
};
