import React, { useEffect, useRef, useState } from 'react';
import { string, node } from 'prop-types';

import { COLORS } from '../../themes/constants';

import {
  ProfileImageSquare,
  ProfileImageInitials,
  ProfileImagePhoto,
} from './ProfileImageStyles';

const colors = [
  'sky',
  'canary',
  'grass',
  'crimson',
  'rust',
  'lavender',
  'khaki',
  'taupe',
];

export const ProfileImage = ({
  src,
  fullName,
  color,
  size,
  className,
  children,
}) => {
  const imageLoadRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  let initials = '';
  let initialsBgColor = color;

  useEffect(() => {
    if (!src && imageLoaded) setImageLoaded(false);
  }, [src, imageLoaded]);

  if (fullName) {
    const allInitials = fullName
      .trim()
      .split(' ')
      .map(name => name.trim().charAt(0).toUpperCase());
    initials = `${allInitials[0]}${allInitials.at(-1)}`;

    initialsBgColor =
      COLORS[
        colors[
          ('abcdefghijklmnopqrstuvwxyz'.indexOf(allInitials[0].toLowerCase()) +
            1) %
            colors.length
        ]
      ];
  }

  return (
    <ProfileImageSquare className={className} size={size}>
      {src?.length > 0 && !imageLoaded && (
        <img
          src={src}
          style={{ display: 'none' }}
          ref={imageLoadRef}
          alt="only used for loading verification"
          onLoad={() => setImageLoaded(true)}
        />
      )}

      {imageLoaded ? (
        <ProfileImagePhoto src={src} data-test="PROFILEIMAGE" />
      ) : (
        <ProfileImageInitials bgColor={initialsBgColor} initials={initials} />
      )}
      {children}
    </ProfileImageSquare>
  );
};

ProfileImage.defaultProps = {
  size: null,
  className: null,
  src: null,
  color: null,
  children: null,
};

ProfileImage.propTypes = {
  children: node,
  className: string,
  color: string,
  fullName: string.isRequired,
  size: string,
  src: string,
};
