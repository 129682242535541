import { useParams } from 'react-router-dom';
import {
  useApiWithErrors,
  GoneError,
  ApiError,
  useApi,
  UnprocessableEntityError,
} from '@axiom/ui';
import { Account, AfcSubmission, InstantMatch } from '@axiom/validation';
import { AccountConst, RolesType } from '@axiom/const';

import { AccountSubmissionsApi } from '../api/account-submissions-api';
import { CandidateApi } from '../api/candidate-api';
import { EngagementTalentUtil } from '../components/EngagementTalent/engagement-talent-util';
import { SubmissionsUtil } from '../utils/submissions-util';
import { InstantMatchesApi } from '../api/instant-matches-api';

interface Args {
  account?: Account;
  submissionId?: AfcSubmission['id'];
}
const { roleDisplayOrder, sortOrderObject } = EngagementTalentUtil;

export const useCurrentSubmissionData = (props: Args = {}) => {
  const params = useParams();
  const talentSlug = params?.talentSlug;
  const urlSubmissionId = params?.submissionId;
  const urlAccountId = params?.accountId;
  const accountId = props?.account?.id ?? urlAccountId;
  const submissionId = props?.submissionId ?? urlSubmissionId;
  const [responseSubmission] = useApiWithErrors(
    accountId &&
      submissionId &&
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId
      )
  );

  let invalidSubmissionRequest = false;

  if (responseSubmission instanceof ApiError) {
    if (responseSubmission instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw responseSubmission;
    }
  }

  const currentSubmission: AfcSubmission | null =
    SubmissionsUtil.getNonInterviewingCandidates(responseSubmission?.data);

  const [{ data: instantMatches } = { data: [] }] = useApi(
    currentSubmission?.submissionId &&
      InstantMatchesApi.readInstantMatches(currentSubmission.submissionId)
  );

  // useApi does not handle falsy chains, only undefined chainables, so will need to fix with APCORE-2573
  const searchInstantMatchConditional =
    props.account?.accountType === AccountConst.Types.Direct &&
    !instantMatches[0]?.candidateId &&
    currentSubmission?.positions[0]?.candidateOpportunities &&
    !currentSubmission?.positions[0]?.candidateOpportunities[0]?.id
      ? true
      : undefined;

  const [responseInstantMatches] = useApiWithErrors(
    searchInstantMatchConditional &&
      InstantMatchesApi.getInstantMatches(currentSubmission.submissionId)
  );

  let searchInstantMatchResponse: InstantMatch[] =
    responseInstantMatches?.data ?? [];
  if (responseInstantMatches instanceof ApiError) {
    if (responseInstantMatches instanceof UnprocessableEntityError) {
      searchInstantMatchResponse = [];
    } else {
      throw responseInstantMatches;
    }
  } else if (searchInstantMatchResponse.length > 0) {
    InstantMatchesApi.refreshInstantMatches(submissionId);
  }

  let availableInstantMatches = instantMatches.filter(match => !match.passed);
  if (searchInstantMatchResponse && availableInstantMatches.length === 0) {
    availableInstantMatches = searchInstantMatchResponse.filter(
      match => !match.passed
    );
  }

  const sortedPositions = currentSubmission?.positions?.sort(
    (a, b) => sortOrderObject[a.role] - sortOrderObject[b.role]
  );

  if (availableInstantMatches.length > 0 && sortedPositions.length === 1) {
    availableInstantMatches.forEach(match => {
      if (
        !sortedPositions[0].candidateOpportunities?.some(
          candidateOpportunity =>
            candidateOpportunity.candidate.id === match.candidate.id
        )
      ) {
        sortedPositions[0].candidateOpportunities?.push({
          candidate: match.candidate,
          badge: match.orderNumber < 4 ? 'Top Match' : null,
          displayBillingRate: 'hourly',
          proposedHourlyRate: match.rate,
          isInstantMatch: true,
        });
      }
    });
  }
  const matchesCount = currentSubmission?.positions?.flatMap(
    position => position.candidateOpportunities
  )?.length;
  const filterPositionByRole = (role: RolesType) =>
    currentSubmission?.positions
      ?.filter(position => position.role === role)
      ?.flatMap(position => position.candidateOpportunities);

  const groupedCandidateOpps = roleDisplayOrder
    .map(order => ({
      title: order,
      data: filterPositionByRole(order),
    }))
    .filter(item => item?.data?.length > 0);

  const currentSubmissionCandidateWithSlug =
    talentSlug &&
    sortedPositions
      ?.find(p =>
        p?.candidateOpportunities?.find(
          candidateOpp => candidateOpp?.candidate?.id === talentSlug
        )
      )
      ?.candidateOpportunities.find(cOpp => cOpp.candidate.id === talentSlug);

  const currentSubmissionCandidateToDisplay =
    currentSubmissionCandidateWithSlug ??
    sortedPositions?.find(position => position?.candidateOpportunities?.length)
      ?.candidateOpportunities?.[0];
  const [{ data: groupedExperiences } = { data: [] }] = useApi(
    currentSubmissionCandidateToDisplay?.candidate?.id &&
      CandidateApi.readCandidateGroupedExperiences(
        currentSubmissionCandidateToDisplay.candidate.id,
        { submissionId }
      )
  );
  return {
    accountId,
    currentSubmission,
    currentSubmissionCandidateToDisplay,
    currentSubmissionCandidateWithSlug,
    groupedCandidateOpps,
    invalidSubmissionRequest,
    talentSlug,
    groupedExperiences,
    isDetailsViewUrl: talentSlug && currentSubmissionCandidateWithSlug,
    sortedPositions,
    submissionId,
    matchesCount,
    instantMatches,
  };
};
