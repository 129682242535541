import React from 'react';
import { Trans } from '@lingui/macro';

import { CandidateAccountShape } from '../../models/candidate';
import { DateUtil } from '../../utils/date-util';

export const MyBenchExpectedChange = ({ candidate }) =>
  candidate.soonestEngagementEndDate ? (
    <Trans>
      Expected to change on{' '}
      <span data-test="SOONEST_ENGAGE_END_DATE">
        {DateUtil.displayDateFullYearShortMonth(
          candidate.soonestEngagementEndDate
        )}
      </span>
    </Trans>
  ) : (
    <Trans>No expected change</Trans>
  );

MyBenchExpectedChange.propTypes = {
  candidate: CandidateAccountShape.isRequired,
};
