import { shape } from 'prop-types';

import AbstractTableStore from '../classes/abstract-table-store';
import { CandidateApi } from '../api/candidate-api';
import { searchResultsShape } from '../models/search';

class SearchStoreClass extends AbstractTableStore {
  formatMetaForRequest(meta) {
    return {
      page: meta.serverMeta.currentPage || 1,
      search: meta.searchConfig?.search,
      sort: meta.searchConfig?.sort,
      filters: {
        ...meta.searchConfig.filters,
      },
    };
  }

  getResults(sendData) {
    const {
      page,
      filters: { search, sort, ...filters },
    } = sendData;

    return CandidateApi.readCandidateSearchResults({
      page,
      search,
      sort,
      filters: { ...filters },
      pageSize: 12,
    });
  }
}

export const SearchStore = new SearchStoreClass({
  filterShape: shape({}),
  dataShape: searchResultsShape,
});
