import React from 'react';
import {
  Card,
  CardSection,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Paragraph,
  SectionHeader,
  SmallHeader,
  CardHeader,
  EmptyLayout,
  ClientEngagementsDetailsContent,
  ClientEngagementStartNewProject,
  ClientContacts,
  useApi,
} from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import EngagementWindowIcon from '../../public/svg/engagement-window-icon.svg';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { EnvUtil } from '../../utils/env-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useCurrentSubmissionData } from '../../hooks/useCurrentSubmissionData';

export const MyEngagementsItemDetails = () => {
  const { account } = useUserAccountData();
  const { currentSubmission } = useCurrentSubmissionData({
    account,
  });
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());

  return (
    <Grid name="engagement-details-tab">
      <GridRow>
        <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
          <Gutter bottom="16px">
            {currentSubmission ? (
              <Card name="SUBMISSION_DETAILS">
                <CardHeader>
                  <SmallHeader name="JOB_NAME">
                    {currentSubmission.jobName}
                  </SmallHeader>
                </CardHeader>
                <CardSection>
                  <SectionHeader>Engagement Details</SectionHeader>
                  <Gutter bottom="24px" />
                  <ClientEngagementsDetailsContent
                    submission={currentSubmission}
                    practiceAreas={practiceAreas}
                  />
                </CardSection>
              </Card>
            ) : (
              <Card name="ENGAGEMENT_CARD">
                <CardSection>
                  <EmptyLayout
                    graphic={<EngagementWindowIcon />}
                    graphicPosition="right"
                  >
                    <SmallHeader>
                      Axiom is reviewing your engagement
                    </SmallHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      We are verifying a few details to ensure our legal talent
                      specialists have everything they need to find exactly what
                      you're looking for. While we do that you have some instant
                      matches to review.
                    </Paragraph>
                  </EmptyLayout>
                </CardSection>
              </Card>
            )}
          </Gutter>
        </GridColumn>
        <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
          <Gutter bottom="16px">
            <ClientEngagementStartNewProject
              newTab={account.accountType !== AccountConst.Types.Direct}
              talentFinderUrl={
                account.accountType === AccountConst.Types.Direct
                  ? `/my-axiom/account/${account.id}/new-engagement`
                  : EnvUtil.hubspotTalentFinder
              }
            />
          </Gutter>
          <ClientContacts
            opportunityOwner={currentSubmission?.salesLead}
            cxPartner={account.cxPartner}
            ownerUser={account.ownerUser}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
