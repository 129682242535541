import { shape, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { AccountApi } from '../api/account-api';
import { AccountLegacyApi } from '../api/account-legacy-api';

import { PreloadedUserStore } from './preloaded-user-store';

const FormDigitalBenchUpdateAccountDisplayNameValidation =
  AjvUtil.compileSchema({
    required: ['displayName'],
    properties: {
      displayName: {
        title: 'displayName',
        type: 'string',
        minLength: 1,
        maxLength: 255,
      },
    },
  });

class FormDigitalBenchUpdateAccountDisplayNameStoreClass extends FormStore {
  getDataShape() {
    return shape({
      id: string,
      displayName: string,
    });
  }

  load({ id, displayName }) {
    return this.setState({ id, displayName });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormDigitalBenchUpdateAccountDisplayNameValidation,
      { ...formData }
    );
  }

  submit(changedFields, formData) {
    if (isEmpty(changedFields)) return this.clearState();

    return this.clearState(
      AccountApi.patchAccount(formData.id, changedFields)
    ).then(() => {
      PreloadedUserStore.load(formData.id);
      AccountLegacyApi.refreshAccount(formData.id);
    });
  }
}

export const FormDigitalBenchUpdateAccountDisplayNameStore =
  new FormDigitalBenchUpdateAccountDisplayNameStoreClass();
