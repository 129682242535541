import { PositionsConst, RolesType } from '@axiom/const';

const { Roles } = PositionsConst;

const roleDisplayOrder = [
  Roles.teamLead,
  Roles.teamSecondment,
  Roles.iSecondment,
  Roles.counsel,
  Roles.other,
];

const sortOrderObject = roleDisplayOrder.reduce(
  (obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  },
  {} as Record<RolesType, number>
);

export const EngagementTalentUtil = {
  roleDisplayOrder,
  sortOrderObject,
};
