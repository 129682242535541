import React from 'react';
import {
  Button,
  SidedrawerFooter,
  SidedrawerHeader,
  SidedrawerSection,
  SidedrawerUtil,
  SmallHeader,
} from '@axiom/ui';
import { Plural } from '@lingui/macro';
import { connect } from 'react-redux';
import { bool } from 'prop-types';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { TalentSearchFiltersForm } from '../TalentSearchFiltersForm/TalentSearchFiltersForm';
import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { SearchStore } from '../../stores/search-store';

import { FiltersSidedrawerBlock } from './TalentSearchStyles';

export const TalentSearchFilterSidedrawerComponent = ({
  disabled,
  gridState,
}) => {
  return (
    <FiltersSidedrawerBlock>
      <StoreStateTreatment
        name="talent_search_treatment"
        showInitialLoader={false}
        showRefetchLoader={false}
        storeState={gridState}
        renderLoadedView={({ data: grid }) => {
          return (
            <>
              <SidedrawerHeader
                onClose={() => SidedrawerUtil.remove('filters')}
              >
                <SmallHeader>Filters</SmallHeader>
              </SidedrawerHeader>
              <SidedrawerSection>
                <TalentSearchFiltersForm
                  disabled={disabled}
                  talentCount={grid?.meta?.serverMeta?.resultCount || 0}
                />
              </SidedrawerSection>
              <SidedrawerFooter>
                <Button
                  className="gtm-talent-search-filters-clear"
                  variation="outline"
                  onClick={() => FormTalentSearchFiltersStore.submit()}
                >
                  Clear All
                </Button>
                <Button
                  className="gtm-talent-search-filters-show"
                  onClick={() => SidedrawerUtil.remove('filters')}
                >
                  Show{' '}
                  <Plural
                    value={grid?.meta?.serverMeta?.resultCount || 0}
                    one="#"
                    other="#"
                  />
                </Button>
              </SidedrawerFooter>
            </>
          );
        }}
      />
    </FiltersSidedrawerBlock>
  );
};

TalentSearchFilterSidedrawerComponent.propTypes = {
  disabled: bool.isRequired,
  gridState: SearchStore.getStateShape().isRequired,
};

export const TalentSearchFilterSidedrawer = connect(state => ({
  gridState: SearchStore.select(state),
}))(TalentSearchFilterSidedrawerComponent);
