import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserConst } from '@axiom/const';
import { Loader, Page, Suspense } from '@axiom/ui';

import AppContainer from './components/AppContainer/AppContainer';
import { AuthRoute } from './routes/AuthRoute';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { DefaultPage } from './pages/DefaultPage';
import { BenchPage } from './pages/BenchPage';
import { TalentSearch } from './components/TalentSearch/TalentSearch';
import { MyEngagementsDashboard } from './components/MyEngagements/MyEngagementsDashboard';
import { MyEngagementsItem } from './components/MyEngagements/MyEngagementsItem';
import { EngagementTalent } from './components/EngagementTalent/EngagementTalent';
import { EngagementTalentDetails } from './components/EngagementTalent/EngagementTalentDetails';
import { MyEngagementsItemInterviewTalent } from './components/MyEngagements/MyEngagementsItemInterviewTalent';
import { MyEngagementsItemDetails } from './components/MyEngagements/MyEngagementsItemDetails';
import { SubmissionPdfDownload } from './components/SubmissionPdfDownload/SubmissionPdfDownload';
import { SubmissionPdfData } from './components/SubmissionPdfData/SubmissionPdfData';
import { CandidateBios } from './components/CandidateBios/CandidateBios';
import { AccessLegalTalentPage } from './pages/AccessLegalTalentPage';
import { MyAxiomPage } from './pages/MyAxiomPage';
import { BenchView } from './components/BenchView/BenchView';
import { BenchSubmissions } from './components/BenchSubmissions/BenchSubmissions';
import { TalentDetailPage } from './pages/TalentDetailPage';
import { MyAxiomLandingPage } from './components/MyAxiomLandingPage/MyAxiomLandingPage';
import { Redirecter } from './components/Redirecter/Redirecter';
import { SplitSubmissionPdfDownload } from './components/SplitSubmissionPdfDownload/SplitSubmissionPdfDownload';
import { DirectClientRoute } from './routes/DirectClientRoute';
import { EngagementHeader } from './components/EngagementHeader/EngagementHeader';
import { EngagementPage } from './pages/EngagementPage';
import { PublicEngagement } from './components/PublicEngagement/PublicEngagement';
import { PageLayout } from './layouts/PageLayout/PageLayout';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="my-axiom" />} />
      <Route
        path="bench-submissions/*?"
        element={<Navigate to="/bench/submissions" />}
      />
      <Route path="bench" element={<BenchPage />}>
        <Route path="" element={<BenchView />} />
        <Route path="submissions" element={<BenchSubmissions />} />
      </Route>
      <Route path="access-legal-talent">
        <Route path="page" element={<AccessLegalTalentPage />}>
          <Route path=":pageNumber" element={<TalentSearch />} />
          <Route path="" element={<Navigate to="1" replace />} />
        </Route>
        <Route path="" element={<Navigate to="page" replace />} />
        <Route path=":id" element={<TalentDetailPage />} />
      </Route>

      {/*
      Don't confuse this with 'engagements' <= with an 's'
      this is the public submission aka matches tab
      */}
      <Route
        path="engagement/account/:accountId/submission/:submissionId/matches"
        element={<EngagementPage />}
      >
        <Route
          path=""
          element={
            <Suspense>
              <PublicEngagement />
            </Suspense>
          }
        >
          <Route
            path=":talentSlug?"
            element={
              <Suspense fallback={<Loader type="bars" />}>
                <EngagementTalentDetails />
              </Suspense>
            }
          />
        </Route>
      </Route>

      <Route path="projects/*" element={<Redirecter to="../my-axiom" />} />
      <Route path="engagements/*" element={<Redirecter to="../my-axiom" />} />
      <Route path="my-axiom" element={<MyAxiomPage />}>
        <Route path="account" element={<Outlet />}>
          <Route path=":accountId" element={<Outlet />}>
            <Route path="" element={<MyAxiomLandingPage />} />
            <Route
              path="dashboard"
              element={<Navigate to="../engagements" replace />}
            />
            <Route path="engagements" element={<MyEngagementsDashboard />} />
            <Route path="new-engagement" element={<DirectClientRoute />} />
            <Route path="submission">
              <Route
                path=""
                element={<Navigate to="../engagements" replace />}
              />
              <Route
                path=":submissionId"
                element={
                  <>
                    <EngagementHeader />
                    <MyEngagementsItem />
                  </>
                }
              >
                <Route path="" element={<Navigate to="matches" replace />} />
                <Route path="matches" element={<EngagementTalent />}>
                  <Route
                    path=":talentSlug?"
                    element={
                      <Suspense fallback={<Loader type="bars" />}>
                        <EngagementTalentDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="interviews"
                  element={
                    <Suspense fallback={<Loader type="bars" />}>
                      <MyEngagementsItemInterviewTalent />
                    </Suspense>
                  }
                />
                <Route
                  path="engagement-details"
                  element={
                    <Suspense fallback={<Loader type="bars" />}>
                      <MyEngagementsItemDetails />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<Loader type="bars" />}>
                      <Navigate to="matches" replace />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route
        path="my-axiom"
        element={
          <AppContainer>
            <PageLayout>
              <Outlet />
            </PageLayout>
          </AppContainer>
        }
      >
        <Route path=":accountId">
          <Route
            path=":submissionId"
            element={
              <Suspense fallback={<Loader type="bars" />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              path="pdf"
              element={
                <Page>
                  <SubmissionPdfData />
                </Page>
              }
            />
            <Route
              path="pdf/split"
              element={
                <Suspense>
                  <Outlet />
                </Suspense>
              }
            >
              <Route path="" element={<Navigate to="./download" replace />} />
              <Route path="download" element={<SplitSubmissionPdfDownload />} />
            </Route>
            <Route
              path="pdf/download"
              element={
                <Suspense>
                  <SubmissionPdfDownload onSave={() => window.close()} />
                </Suspense>
              }
            />
            <Route path=":candidateId">
              <Route path="download?/bio/anonymous?">
                <Route path="" element={<CandidateBios />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route
        path="401"
        element={
          <Page title="AxiomLaw - Unauthorized">
            <AppContainer>
              <UnauthorizedPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path="404"
        element={
          <Page title="AxiomLaw - Not Found">
            <AppContainer>
              <NotFoundPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path=""
        element={
          <AuthRoute
            roles={[
              UserConst.Roles.ClientUser,
              UserConst.Roles.EnvoyAdmin,
              UserConst.Roles.EnvoyUser,
            ]}
          >
            <Page title="AxiomLaw">
              <AppContainer>
                <DefaultPage />
              </AppContainer>
            </Page>
          </AuthRoute>
        }
      />
      <Route path="*" element={<Navigate to="404" replace />} />
    </Routes>
  );
};
