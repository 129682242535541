import React from 'react';
import { string, func } from 'prop-types';
import { Trans } from '@lingui/macro';
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalSection,
  ModalFooter,
} from '@axiom/ui';

export const MyBenchConfirmSubmitModal = ({ header, onConfirm }) => {
  return (
    <Modal name="CONFIRM" size="confirm">
      <ModalHeader onClose={onConfirm}>{header}</ModalHeader>
      <ModalSection>
        <Text>
          <Trans>
            Thanks for reaching out. We’ll pass your request along and get back
            to you shortly.
          </Trans>
        </Text>
      </ModalSection>
      <ModalFooter>
        <Button name="GOT_IT" onClick={onConfirm}>
          <Trans>Got it</Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

MyBenchConfirmSubmitModal.propTypes = {
  header: string.isRequired,
  onConfirm: func.isRequired,
};
