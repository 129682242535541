import React from 'react';
import {
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Button,
  Header1,
  IconButton,
  useApiWithErrors,
  ApiError,
  useApi,
} from '@axiom/ui';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { MyBench } from '../MyBench/MyBench';
import { Input } from '../FormElements/Input/Input';
import { isEnvoyUser as getIsEnvoyUser } from '../../utils/roles-util';
import { FormDigitalBenchUpdateAccountDisplayNameStore } from '../../stores/form-digital-bench-update-account-display-name-store';
import { AccountUtil } from '../../utils/account-util';
import { QueryParamUtil } from '../../utils/query-param-util';
import { AccountLegacyApi } from '../../api/account-legacy-api';
import { UserApi } from '../../api/user-api';
import { PracticeAreaApi } from '../../api/practice-areas-api';

export const BenchView = () => {
  const { accountId } = QueryParamUtil.getQueryParams();
  let account = null;

  const [responseAccount] = useApiWithErrors(
    accountId && AccountLegacyApi.getAccount(accountId)
  );

  if (responseAccount && !(responseAccount instanceof ApiError)) {
    account = responseAccount.data;
  }

  const [{ data: user } = { data: {} }, { data: pAreas } = { data: [] }] =
    useApi(
      account && UserApi.readUser(),
      account && PracticeAreaApi.readPracticeAreas()
    );

  const practiceAreas = pAreas?.filter(data => data.type);

  if (!account) {
    return (
      <Gutter bottom="2rem" className="text-align-center">
        <Header1 accent name="BENCH_UNKNOWN">
          Bench not found
        </Header1>
      </Gutter>
    );
  }

  const isEnvoyUser = getIsEnvoyUser(user);
  const benchName = AccountUtil.displayBenchName(account);

  return (
    <Grid name="DIGITAL_BENCH_BODY">
      <GridRow>
        <GridColumn>
          <Gutter>
            <FormStateTreatment
              name="DIGITAL_BENCH_UPDATE_ACCOUNT_DISPLAY_NAME"
              formStore={FormDigitalBenchUpdateAccountDisplayNameStore}
              renderLoadedView={({ fireSubmit, fireCancel }) => (
                <Grid>
                  <GridRow className="bench-account-name-display">
                    <GridColumn smallScreenWidth={12} largeScreenWidth={8}>
                      <div>
                        <Input
                          placeholder={benchName}
                          name="displayName"
                          className="account-name-input"
                        />
                      </div>
                      <div className="form-buttons">
                        <Gutter right="1rem" as="span">
                          <Button
                            name="UPDATE_ACCOUNT_DISPLAY_NAME_CANCEL_BUTTON"
                            variation="outline"
                            className="account-name-cancel-button"
                            onClick={() => fireCancel()}
                          >
                            Cancel
                          </Button>
                        </Gutter>
                        <Button
                          name="UPDATE_ACCOUNT_DISPLAY_NAME_SAVE_BUTTON"
                          onClick={() => fireSubmit()}
                        >
                          Save
                        </Button>
                      </div>
                    </GridColumn>
                  </GridRow>
                </Grid>
              )}
              renderNonLoadedView={() => (
                <div className="bench-account-name-display">
                  <Header1 accent name="DIGITAL_BENCH_ACCOUNT_TITLE">
                    {benchName}
                    {!!isEnvoyUser && (
                      <Gutter left="8px" bottom="5px" as="span">
                        <IconButton
                          pattern="secondary"
                          name="ACCOUNT_DISPLAY_NAME_EDIT_ICON"
                          onClick={() =>
                            FormDigitalBenchUpdateAccountDisplayNameStore.load(
                              account
                            )
                          }
                          icon="pencil"
                        />
                      </Gutter>
                    )}
                  </Header1>
                </div>
              )}
            />
          </Gutter>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          <MyBench
            account={account}
            benchName={benchName}
            practiceAreas={practiceAreas}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
