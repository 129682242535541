import { shape, string } from 'prop-types';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { patchAccountsCandidates } from '../api/account-candidates-api';

import { AccountCandidatesStore } from './account-candidates-store';

const FormDigitalBenchEditHighlightsModalValidation = AjvUtil.compileSchema({
  properties: {
    notes: {
      title: 'notes',
      type: 'string',
      minLength: 0,
      maxLength: 500,
    },
  },
});

class FormDigitalBenchEditHighlightsModalStoreClass extends FormStore {
  getDataShape() {
    return shape({
      accountId: string,
      candidateId: string,
      notes: string,
      calculatedDisplayName: string,
      notesUpdatedByUserFullName: string,
      notesUpdatedAt: string,
    });
  }

  load(accountId, candidateData, practiceAreas, taxonomy) {
    return this.setState({
      accountId,
      candidateId: candidateData?.id,
      notes: candidateData?.calculatedNotes,
      calculatedDisplayName: candidateData?.calculatedDisplayName,
      notesUpdatedByUserFullName: candidateData?.notesUpdatedByUser?.fullName,
      notesUpdatedAt: candidateData?.notesUpdatedAt,
      practiceAreas,
      taxonomy,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormDigitalBenchEditHighlightsModalValidation,
      { ...formData }
    );
  }

  submit(changedFields, { accountId, candidateId, practiceAreas, taxonomy }) {
    return this.clearState(
      patchAccountsCandidates(accountId, candidateId, changedFields)
    ).then(() => {
      return AccountCandidatesStore.load(accountId, practiceAreas, taxonomy);
    });
  }
}

export const FormDigitalBenchEditHighlightsModalStore =
  new FormDigitalBenchEditHighlightsModalStoreClass();
