import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const ProgressStepsItem = ({
  children,
  className,
  stepName,
}: {
  children: React.ReactNode;
  className?: string;
  stepName: string;
}) => {
  return (
    <div
      className={AttrsHelper.formatClassname('progress-step-label', className)}
      data-test={stepName}
    >
      {children}
    </div>
  );
};
