import { bool, shape, string } from 'prop-types';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { patchContact } from '../api/contact-api';

import { PreloadedUserStore } from './preloaded-user-store';

const FormNotificationPreferencesValidation = AjvUtil.compileSchema({
  properties: {
    availabilityNotifications: {
      title: 'availabilityNotifications',
      type: 'boolean',
    },
    benchNotifications: {
      title: 'benchNotifications',
      type: 'boolean',
    },
  },
});

class FormNotificationPreferencesStoreClass extends FormStore {
  getDataShape() {
    return shape({
      accountId: string,
      availabilityNotifications: bool,
      benchNotifications: bool,
      contactId: string,
    });
  }

  load({
    accountId,
    availabilityNotificationOptOut,
    benchNotificationOptOut,
    contactId,
  }) {
    return this.setState({
      accountId,
      availabilityNotifications: !availabilityNotificationOptOut,
      benchNotifications: !benchNotificationOptOut,
      contactId,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormNotificationPreferencesValidation,
      { ...formData }
    );
  }

  async submit(changedFields, formData) {
    const values = {};
    if ('availabilityNotifications' in changedFields) {
      values.availabilityNotificationOptOut =
        !changedFields.availabilityNotifications;
    }
    if ('benchNotifications' in changedFields) {
      values.benchNotificationOptOut = !changedFields.benchNotifications;
    }
    if (Object.keys(values).length > 0) {
      await this.clearState(patchContact(formData.contactId, values));
      return PreloadedUserStore.load(formData.accountId);
    }
    return this.cancel();
  }
}

export const FormNotificationPreferencesStore =
  new FormNotificationPreferencesStoreClass();
