import React, { useEffect, useRef, useState } from 'react';
import {
  useApi,
  PDFExport,
  SplitSubmissionPdf,
  StringUtil,
  PdfPageTemplateFooter,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { EnvUtil } from '../../utils/env-util';

import { PDFWrapper } from './SplitSubmissionPdfDownloadStyles';

export const SplitSubmissionPdfDownload = () => {
  const { accountId, submissionId } = useParams();
  const pdfRoot = useRef(null);
  const [candidateIndex, setCandidateIndex] = useState(0);
  const [
    { data: submissionData },
    { data: practiceAreas },
    { data: taxonomy },
  ] = useApi(
    AccountSubmissionsApi.readAccountSubmissionDetails(accountId, submissionId),
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const candidateOpps = submissionData?.positions?.flatMap(co => {
    return co.candidateOpportunities;
  });

  const allGroupedExperiences = useApi(
    ...candidateOpps.map(({ candidate: { id } }) =>
      CandidateApi.readCandidateGroupedExperiences(id, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const cleanCompanyName = StringUtil.cleanStringForFileName(
    submissionData.jobName
  );

  useEffect(() => {
    if (pdfRoot?.current?.save) {
      setTimeout(() => {
        pdfRoot.current.save(() => {
          if (candidateIndex < candidateOpps.length - 1) {
            setCandidateIndex(candidateIndex + 1);
          }
        });
      }, 500);
    }
  }, [pdfRoot, candidateIndex]);

  const candidateOpp = candidateOpps[candidateIndex];

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        fileName={`${candidateOpp.candidate.calculatedDisplayName} ${cleanCompanyName} Axiom Matches.pdf`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
        pageTemplate={props => (
          <PdfPageTemplateFooter
            pdfProps={props}
            candidate={candidateOpp.candidate}
          />
        )}
      >
        <SplitSubmissionPdf
          submission={submissionData}
          candidateOpp={candidateOpp}
          allGroupedExperiences={allGroupedExperiences}
          practiceAreas={practiceAreas}
          clientUrl={EnvUtil.clientEngagementsUrl}
          loginUrl={EnvUtil.loginUrl}
          taxonomy={taxonomy}
        />
      </PDFExport>
    </PDFWrapper>
  );
};
