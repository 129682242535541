import React from 'react';
import { Gutter } from '@axiom/ui';

import { PublicEngagementHeader } from '../PublicEngagementHeader/PublicEngagementHeader';
import { EngagementTalent } from '../EngagementTalent/EngagementTalent';

export const PublicEngagement = () => {
  return (
    <>
      <PublicEngagementHeader />
      <Gutter bottom="16px" />
      <EngagementTalent />
    </>
  );
};
