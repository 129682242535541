import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Text,
  Label,
  DataTestUtil,
  DataTest,
  Visible,
  ImageCircle,
  Layout,
  Tooltip,
} from '@axiom/ui';
import { TalkConst } from '@axiom/const';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { RichTextEditor } from '../FormElements/RichTextEditor/RichTextEditor';
import { FormInquireWithAxiomModalStore } from '../../stores/form-inquire-with-axiom-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import {
  isLoggedInUser,
  isClientUser as isClientUserUtil,
} from '../../utils/roles-util';
import { SingleSelect } from '../FormElements/SingleSelect/SingleSelect';
import { CreateAccountTosPrivacyNotice } from '../CreateAccountTosPrivacyNotice/CreateAccountTosPrivacyNotice';
import { PersistentTooltip } from '../PersistentTooltip/PersistentTooltip';
import { CardRadioField } from '../CardRadio/CardRadio';

import { InquireWithAxiomModalTooltip } from './InquireWithAxiomModalTooltip';
import {
  IWAMLabel,
  IWAMColoredText,
  IWAMLabelWrapper,
  IWAMCustomError,
  IWAMPInputWrapper,
  IWAMPRadioWrapper,
  IWAMPRadioLabelWrapper,
  IWAMRequiredNote,
} from './InquireWithAxiomModalStyles';

const getAccountOptions = (accounts = []) =>
  accounts.map(account => ({
    value: account.name,
    label: account.calculatedName,
  }));

const InquireWithAxiomModalComponent = ({ user }) => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [hasBlockedEmail, setHasBlockedEmail] = useState(false);
  const contact = user?.contact;
  // Because the formState is cleared on submission, the candidate name must be stored
  // so it can be displayed in the thank you modal that follows
  const [talentName, setTalentName] = useState(null);

  // Input References
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);

  // Submit References
  const submitRef = useRef(null);

  // Visibility and meta
  const closeThankYou = () => setShowThankYou(false);

  const hasUser = user !== undefined && user !== null;

  const isClientUser = isClientUserUtil(user);
  const canSubmit = !isLoggedInUser(user) || isClientUser;
  const hasMultipleAccounts = isClientUser && contact?.accounts?.length > 1;

  const renderLabel = (text, isRequired = true, showInfoIcon = false) => (
    <IWAMLabelWrapper>
      <Label>
        <span>{text}</span>{' '}
        {!!isRequired && <IWAMColoredText>*</IWAMColoredText>}
      </Label>
      {user && showInfoIcon ? (
        <Tooltip name={`${text}_CLIENT_TOOLTIP_ICON`} position="top">
          <InquireWithAxiomModalTooltip user={user} title={text} />
        </Tooltip>
      ) : (
        // takes up same vert space as icon for tool tip
        // used to keep fields in line with each other
        <div style={{ height: '32px' }} />
      )}
    </IWAMLabelWrapper>
  );

  return (
    <FormStateTreatment
      isModalState
      name="INQUIRE_WITH_AXIOM_TREATMENT"
      formStore={FormInquireWithAxiomModalStore}
      onSubmit={result => {
        if (result?.errorType === 'BLOCKED_EMAIL') {
          setHasBlockedEmail(true);
        } else {
          FormInquireWithAxiomModalStore.clearState(result);
          setShowThankYou(true);
        }
      }}
      renderNonLoadedView={() =>
        showThankYou && (
          <Modal size="confirm">
            <ModalHeader onClose={closeThankYou}>
              <Layout position="middle" horizontalGutter="16px">
                <div>We're on it</div>
              </Layout>
            </ModalHeader>
            <ModalSection>
              We'll review your request and get back to you shortly with{' '}
              {talentName.firstName}'s rates and availability, typically within
              1-2 business hours.
            </ModalSection>
            <ModalFooter>
              <Button name="got_it" onClick={closeThankYou}>
                Got it!
              </Button>
            </ModalFooter>
          </Modal>
        )
      }
      renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
        <Modal name="INQUIRE_WITH_AXIOM_FORM">
          <ModalHeader onClose={fireCancel}>
            <Layout position="middle" horizontalGutter="16px">
              <Visible only="largeScreen">
                <ImageCircle
                  size="small"
                  imageName={formData.candidateName}
                  src={formData.candidatePhotoURL}
                />
              </Visible>
              <div>Interested in {formData.candidateName}?</div>
            </Layout>
          </ModalHeader>
          <ModalSection>
            <>
              <IWAMPRadioWrapper>
                <Grid stackable>
                  <GridRow>
                    <GridColumn>
                      <Gutter bottom="1rem">
                        <IWAMRequiredNote>
                          <Text
                            name="Required Note"
                            className="text-align-right"
                          >
                            <IWAMColoredText>*</IWAMColoredText>{' '}
                            <IWAMLabel muted>Required</IWAMLabel>
                          </Text>
                        </IWAMRequiredNote>
                        <IWAMPRadioLabelWrapper>
                          {renderLabel('Please Select One', true)}
                          <Text size="small">
                            We'll get back to you within 1 business day.
                          </Text>
                        </IWAMPRadioLabelWrapper>
                      </Gutter>
                    </GridColumn>
                  </GridRow>
                  <GridRow stretched>
                    <GridColumn width={6}>
                      <CardRadioField
                        label={`Talk to ${formData.candidateName} directly`}
                        subtext="Please send me their rates and availability to talk"
                        name="requestType"
                        value={TalkConst.requestType.Talk}
                      />
                    </GridColumn>
                    <GridColumn width={6}>
                      <CardRadioField
                        label="Get info from Axiom"
                        subtext={`I want to talk to someone at Axiom about ${formData.candidateName}`}
                        name="requestType"
                        value={TalkConst.requestType.Inquire}
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </IWAMPRadioWrapper>
              {!!hasMultipleAccounts && (
                <Gutter gutterType="margin" bottom="1rem">
                  <Grid>
                    <GridRow>
                      <GridColumn>
                        <SingleSelect
                          name="accountName"
                          label={renderLabel('Select account', true, false)}
                          options={getAccountOptions(contact?.accounts)}
                          onChange={accountName => {
                            formData.accountName = accountName;
                          }}
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </Gutter>
              )}
              <Grid stackable>
                <GridRow>
                  <GridColumn>
                    <Gutter bottom="20px">
                      <Gutter bottom="0.75rem">
                        <Label>Tell us more about your engagement</Label>
                      </Gutter>
                      <RichTextEditor
                        name="workDescription"
                        maxLength={1000}
                        placeholder="Additional information about the type of work, any
                          special qualifications you need, an estimated start
                          date, and duration will help us serve you better."
                      />
                    </Gutter>
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={6}>
                    <Gutter bottom="1rem">
                      <IWAMPInputWrapper
                        label={renderLabel(
                          'First Name',
                          true,
                          isClientUser,
                          firstNameRef
                        )}
                        name="firstName"
                        maxLength={null}
                        disabled={hasUser}
                      />
                    </Gutter>
                  </GridColumn>
                  <GridColumn width={6}>
                    <Gutter bottom="1rem">
                      <IWAMPInputWrapper
                        label={renderLabel(
                          'Last Name',
                          true,
                          isClientUser,
                          lastNameRef
                        )}
                        name="lastName"
                        maxLength={null}
                        disabled={hasUser}
                      />
                    </Gutter>
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={6}>
                    <Gutter bottom="1rem">
                      <IWAMPInputWrapper
                        label={renderLabel(
                          'Business Email',
                          true,
                          isClientUser,
                          emailRef
                        )}
                        name="email"
                        onFocus={() => setHasBlockedEmail(false)}
                        maxLength={null}
                        disabled={hasUser}
                      />
                      {!!hasBlockedEmail && (
                        <IWAMCustomError
                          data-test={DataTestUtil.format('blocked_email_error')}
                        >
                          Please enter a business email.
                        </IWAMCustomError>
                      )}
                    </Gutter>
                  </GridColumn>
                  <GridColumn width={6}>
                    <Gutter bottom="1rem">
                      <IWAMPInputWrapper
                        label={renderLabel('Phone Number', false, false)}
                        name="phoneNumber"
                        maxLength={null}
                      />
                    </Gutter>
                  </GridColumn>
                </GridRow>
                <CreateAccountTosPrivacyNotice user={user} withMakeAccount />
              </Grid>
            </>
          </ModalSection>
          <ModalFooter>
            <PersistentTooltip
              targetElement={submitRef}
              position="top"
              openDelay={0}
              tooltipContent={
                <DataTest value="UNAUTHORIZED_TOOLTIP_TEXT">
                  Your account doesn't support this feature
                </DataTest>
              }
            >
              <div ref={canSubmit ? null : submitRef} title=" ">
                <Button
                  fluid
                  onClick={() => {
                    setTalentName({
                      fullName: formData.candidateName,
                      firstName: formData.candidateFirstName,
                    });
                    fireSubmit();
                  }}
                  name="submit"
                  disabled={!canSubmit}
                >
                  {isLoggedInUser(user) ? 'Submit' : 'Agree and Submit'}
                </Button>
              </div>
            </PersistentTooltip>
          </ModalFooter>
        </Modal>
      )}
    />
  );
};

InquireWithAxiomModalComponent.propTypes = {
  user: PreloadedUserStore.getDataShape(),
};

InquireWithAxiomModalComponent.defaultProps = {
  user: null,
};

export const InquireWithAxiomModal = connect(state => ({
  user: PreloadedUserStore.selectData(state),
}))(InquireWithAxiomModalComponent);
