import { Api } from '@axiom/ui';
import { Account, AccountIntake } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AccountIntakeApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  getAccountIntake(accountId: Account['id']) {
    return super.read<{ data: AccountIntake }>({
      endpoint: `/intake/details/account/${accountId}`,
      method: 'GET',
    });
  }

  refreshAccountIntake(accountId: Account['id']) {
    return super.invalidate(`/intake/details/account/${accountId}`);
  }
}

export const AccountIntakeApi = new AccountIntakeApiClass();
