import { SchemaDate, SchemaLocation } from '@axiom/types';
import { z } from 'zod';
import { IndustryValue, PositionsConst } from '@axiom/const';
import { DateUtil } from '@axiom/ui';
import moment from 'moment';
import {
  LevelOfExperience,
  levelOfExperienceValues,
  LicensedLawyer,
  licensedLawyerValues,
  Occupation,
  occupationTypeValues,
  positionReservedTypeValues,
  PositionReserved,
} from '@axiom/validation';

export const billingUnitValues = [5, 10, 15, 20, 25, 30, 35, 40] as const;

export const worksiteValues = {
  [PositionsConst.Worksites.Remote]: 'No, remote is fine',
  [PositionsConst.Worksites.OnsiteRemote]: 'Yes, periodically in our office',
  [PositionsConst.Worksites.Onsite]: 'Yes, full time in our office',
} as const;

export function checkDateRange(
  context: z.RefinementCtx,
  errorPath: string,
  startDate: string,
  endDate: string
) {
  if (!DateUtil.isDateAfterDate(endDate, moment(startDate).toDate())) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be after start date',
      path: [errorPath],
    });
  }
}

export function checkHourlyRate(
  context: z.RefinementCtx,
  errorPath: string,
  minHourlyRate: number,
  maxHourlyRate: number
) {
  if (maxHourlyRate < minHourlyRate) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be more than min hourly rate',
      path: [errorPath],
    });
  }
}

export const selfServiceDescriptionMaxLength = 150;

export const projectNeedsSchema = z.object({
  billingUnitsPerWeek: z.number().gte(5).lte(40),
  companyLocation: SchemaLocation.nullable().optional(),
  description: z
    .string()
    .max(selfServiceDescriptionMaxLength)
    .nullable()
    .optional(),
  endDate: SchemaDate,
  primaryFocusArea: z.string(),
  generalSkills: z.array(z.string()).nullish().optional(),
  industry: z
    .enum(Object.keys(IndustryValue) as NonEmptyArray<IndustryValue>)
    .nullable()
    .optional(),
  languageId: z.string().uuid().nullable().optional(),
  legalTechSkills: z.array(z.string()).nullish().optional(),
  levelOfExperience: z.enum(
    Object.keys(levelOfExperienceValues) as NonEmptyArray<LevelOfExperience>
  ),
  licensedLawyer: z.enum(
    Object.keys(licensedLawyerValues) as NonEmptyArray<LicensedLawyer>
  ),
  licensedLawyerState: z.string(),
  maxBudgetPerHour: z
    .number({
      invalid_type_error: 'Must be more than $100',
    })
    .positive()
    .gte(100, { message: 'Must be more than $100' }),
  minBudgetPerHour: z
    .number({
      invalid_type_error: 'Enter a number over $0',
    })
    .positive()
    .gte(1, { message: 'Enter a number over $0' }),
  occupationType: z.enum(
    Object.keys(occupationTypeValues) as NonEmptyArray<Occupation>
  ),
  otherFocusAreas: z.array(z.string()).nullish().optional(),
  reservedType: z.enum(
    Object.keys(positionReservedTypeValues) as NonEmptyArray<PositionReserved>
  ),
  startDate: SchemaDate.superRefine((value, ctx) => {
    if (DateUtil.isDateAfterDate(moment(), moment(value).toDate())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be today or after',
      });
    }
  }),
  worksite: z.enum(Object.keys(worksiteValues) as NonEmptyArray<string>),
});

export type ProjectNeeds = z.infer<typeof projectNeedsSchema>;

export type ProjectNeedsComplete = ProjectNeeds & {
  legalSkills: string[];
};

export type ProjectNeedsFormType = z.infer<typeof projectNeedsSchema>;

export type WorkSiteValue =
  (typeof worksiteValues)[keyof typeof worksiteValues];

export type ProjectNeedsForm = z.infer<typeof projectNeedsSchema>;
