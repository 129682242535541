import isUuid from 'is-uuid';

export const UrlVariableParamUtil = (params = {}) => {
  const uuids = new Set(['candidateId', 'submissionId', 'token']);
  const data = {
    invalid: false,
    valid: true,
  };

  for (const key in params) {
    if (
      (uuids.has(key) && !isUuid.anyNonNil(params[key])) ||
      (key === 'pageNumber' && !Number.parseInt(params[key], 10))
    ) {
      data.invalid = true;
      data.valid = false;
      break;
    }
  }

  return data;
};
