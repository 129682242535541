import React from 'react';
import {
  Button,
  Card,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';

import BustGroup from '../../public/svg/bust-group.svg';
import { useUserAccountData } from '../../hooks/useUserAccountData';

export const BrowseTalentBenchCta = () => {
  const { account } = useUserAccountData();

  return (
    <Card name="MY_BENCH_CTA_CONTAINER">
      <CardSection>
        <Layout stackableOn="mobile">
          <LayoutItem>
            <Gutter right="24px" bottom="24px">
              <BustGroup width="117px" height="117px" />
            </Gutter>
          </LayoutItem>
          <LayoutItem>
            <ParagraphHeader>My bench</ParagraphHeader>
            <Gutter vertical="16px">
              <Paragraph>
                Discover and reconnect with trusted talent from your
                organization's pool of active and past Axiom lawyers. Simplify
                the hiring and onboarding processes by exploring and reviewing
                talent from your bench.
              </Paragraph>
            </Gutter>
            <Button
              name="MY_BENCH_CTA"
              pattern="secondary"
              to={`/bench?account=${account.id}`}
            >
              View Bench
            </Button>
          </LayoutItem>
        </Layout>
      </CardSection>
    </Card>
  );
};
