import React from 'react';
import {
  Gutter,
  HeaderBar,
  Layout,
  LayoutBleed,
  LayoutItem,
  Sticky,
  StickyLayout,
  Suspense,
} from '@axiom/ui';
import styled from 'styled-components';

import { Footer } from '../../components/Footer/Footer';
import { AuthenticatedHeaderMenu } from '../AuthenticatedHeaderMenu/AuthenticatedHeaderMenu';
import { SubNavigationHeader } from '../SubNavigationHeader/SubNavigationHeader';

const PageBlock = styled.div`
  min-height: 100vh;

  & > * {
    min-height: 100vh;
  }
`;

export const PageLayout = ({
  children,
  name,
  showLoggedUser = false,
  hideFooter,
}: {
  children: React.ReactNode;
  name?: string;
  showLoggedUser?: boolean;
  hideFooter?: boolean;
}) => {
  return (
    <StickyLayout>
      <PageBlock>
        <Layout direction="vertical" name={name}>
          <LayoutItem>
            <Sticky>
              <HeaderBar name="TOPNAV" logoUrl="/my-axiom">
                {!!showLoggedUser && <AuthenticatedHeaderMenu />}
              </HeaderBar>
              {!!showLoggedUser && <SubNavigationHeader />}
              <Gutter bottom="24px" />
            </Sticky>
          </LayoutItem>
          <LayoutItem fluid name="FLUID">
            <Suspense>
              <LayoutBleed>{children}</LayoutBleed>
            </Suspense>
          </LayoutItem>
          {!hideFooter && (
            <LayoutItem>
              <Footer />
            </LayoutItem>
          )}
        </Layout>
      </PageBlock>
    </StickyLayout>
  );
};
