import React from 'react';
import { Candidate, CandidateIndustry, User } from '@axiom/validation';
import {
  Card,
  CardSection,
  CardFooter,
  Button,
  Well,
  ImageCircle,
  Layout,
  CandidateProfileUtil,
  ParagraphHeader,
  Icon,
  CondensedLarge,
  Gutter,
  CondensedHeader,
  useApi,
  PracticeAreaUtil,
  LightTheme,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';

import { FormInquireWithAxiomModalStore } from '../../stores/form-inquire-with-axiom-modal-store';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { LinkUtil } from '../../utils/link-util';
import { TalentCarouselBadge } from '../TalentCarouselBadge/TalentCarouselBadge';

export const SavedTalentCarouselCard = ({
  candidate,
  industryValue,
  user,
}: {
  candidate: Candidate;
  industryValue: CandidateIndustry['industryValue'];
  user: User;
}) => {
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );
  const { industries } = taxonomy;

  const findIndustry = () => {
    const industry = candidate.industries?.find(
      ind => ind.industryValue === industryValue
    );
    const maxIndustry = candidate.industries.sort(
      (a, b) => +b.yearsOfExperience - +a.yearsOfExperience
    )[0];
    return industry ?? maxIndustry;
  };

  const industryDisplayText = () => {
    const industry = findIndustry();
    const industryYOE = industry?.yearsOfExperience;
    const industryName = industries?.[industry?.industryValue]?.displayValue;
    if (industryYOE && Math.round(+industryYOE) === 0) {
      return `Less than 1 Year in ${industryName}`;
    }
    return `${Math.round(+industryYOE)} Years in ${industryName}`;
  };

  const candidateExperienceDisplayText = () => {
    const candidateYOE = candidate.yearsOfExperience || 0;
    const oneYearValue = 1;
    const twentyYearValue = 20;
    if (candidateYOE < oneYearValue) {
      return 'Less than 1 Year of experience';
    } else if (candidateYOE && candidateYOE >= twentyYearValue) {
      return '20+ Years of experience';
    }
    return `${Math.round(candidateYOE)} Years of experience`;
  };

  return (
    <Card name="SAVED_TALENT_CAROUSEL_CARD" stretched>
      <CardSection divider>
        <Well background="light blue">
          <Layout horizontalGutter="16px" position="middle">
            <div>
              <ImageCircle
                name="SAVED_TALENT_IMAGE"
                size="small"
                background="blue"
                imageName={candidate.calculatedDisplayName}
                src={CandidateProfileUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </div>

            <Layout direction="vertical" verticalGutter="8px">
              <ParagraphHeader name="SAVED_TALENT_NAME">
                {candidate.calculatedDisplayName}
              </ParagraphHeader>
              <LightTheme backgroundName="none">
                <TalentCarouselBadge candidate={candidate} isSavedCarousel />
              </LightTheme>
            </Layout>
          </Layout>
          <Gutter bottom="16px" />
          <Layout verticalGutter="16px" direction="vertical">
            <Layout horizontalGutter="16px">
              <Icon name="checkmark" />
              <CondensedLarge name="SAVED_CANDIDATE_EXPERIENCE">
                {candidateExperienceDisplayText()}
              </CondensedLarge>
            </Layout>
            {candidate.industries.length > 0 && (
              <Layout horizontalGutter="16px">
                <Icon name="checkmark" />
                <CondensedLarge name="SAVED_INDUSTRY_EXPERIENCE">
                  {industryDisplayText()}
                </CondensedLarge>
              </Layout>
            )}
          </Layout>
        </Well>
        <Gutter bottom="16px" />
        <CondensedHeader>Primary Practice Area</CondensedHeader>
        <CondensedLarge name="SAVED_PRACTICE_AREA">
          {PracticeAreaUtil.formatPracticeAreaSpecialty(
            practiceAreas,
            candidate.practiceArea?.id
          )}
        </CondensedLarge>
      </CardSection>
      <CardFooter>
        <Button
          pattern="secondary"
          variation="outline"
          toTab={LinkUtil.getSharePath(candidate, practiceAreas)}
          name="VIEW_TALENT_BUTTON"
        >
          View Talent
        </Button>
        <Button
          name="REQUEST_INFO_BUTTON"
          pattern="secondary"
          onClick={() => {
            FormInquireWithAxiomModalStore.load(candidate, user);
          }}
        >
          Request Info
        </Button>
      </CardFooter>
    </Card>
  );
};
