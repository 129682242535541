import { arrayOf, bool, shape, string } from 'prop-types';
import moment from 'moment';

import GenericStore from '../classes/generic-store';
import {
  getAccountCandidates,
  patchAccountsCandidates,
} from '../api/account-candidates-api';
import { CandidateAccountShape } from '../models/candidate';

const searchableDateFormats = [
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MM-DD-YY',
  'MM/DD/YY',
  'M/D/YY',
  'M-D-YY',
  'M/D/YYYY',
  'M-D-YYYY',
];

class AccountCandidatesStoreClass extends GenericStore {
  load(accountId, practiceAreas, taxonomy) {
    return this.setState(
      getAccountCandidates(accountId).then(data => {
        const formattedData = this.makeSearchableText(
          data,
          practiceAreas,
          taxonomy
        );

        return {
          meta: {
            sort: '',
            filters: {
              practiceAreaId: '',
              status: '',
              keyword: null,
            },
          },
          originalData: [...formattedData],
          data: [...formattedData],
          noFilterData: false,
        };
      })
    );
  }

  makeSearchableText(candidates, practiceAreas, taxonomy) {
    const practiceAreasById = practiceAreas.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    return candidates.map(c => {
      const searchableText = [];

      searchableText.push(
        `${c.calculatedDisplayName}`.toLowerCase(),
        `${c.weeklyAvailability}`,
        `${c.soonestEngagementEndDate}`
      );
      const mDate = moment(c.soonestEngagementEndDate, 'YYYY-MM-DD');
      searchableDateFormats.forEach(format => {
        searchableText.push(mDate.format(format));
      });

      searchableText.push(`${c.calculatedNotes}`.toLowerCase());

      c.degrees.forEach(deg =>
        searchableText.push(
          `${deg.institution} ${deg.yearAwarded}`.toLowerCase()
        )
      );

      c.barredLocations.forEach(b =>
        searchableText.push(`${b.name}`.toLowerCase())
      );

      searchableText.push(`${c.billingUnitsPerWeek}`, `${c.weeklyTimeUnit}`);

      c.industries.forEach(industry =>
        searchableText.push(
          `${taxonomy.industries[
            industry.industryValue
          ].displayValue.toLowerCase()}`
        )
      );

      const practiceAreaSearchText = c.practiceArea?.name.toLowerCase();
      searchableText.push(`${practiceAreaSearchText}`);

      if (c.practiceArea?.parentId) {
        const practiceAreaParentSearchText =
          practiceAreasById[c.practiceArea.parentId].name.toLowerCase();
        searchableText.push(
          `${practiceAreaParentSearchText}`,
          `${practiceAreaParentSearchText} - ${practiceAreaSearchText}`
        );
      }

      searchableText.push(
        `${c.lawSchool?.toLowerCase()}`,
        `${c.addressState?.toLowerCase()}`,
        `${c.addressCountry?.toLowerCase()}`
      );

      return {
        ...c,
        searchableText: searchableText.join(' '),
      };
    });
  }

  changeSort(sort, accountCandidatesStoreData) {
    return this.sortAndFilterData({
      ...accountCandidatesStoreData,
      meta: {
        ...accountCandidatesStoreData.meta,
        sort,
      },
    });
  }

  changePracticeAreaFilter(value, accountCandidatesStoreData) {
    return this.sortAndFilterData({
      ...accountCandidatesStoreData,
      meta: {
        ...accountCandidatesStoreData.meta,
        filters: {
          ...accountCandidatesStoreData.meta.filters,
          practiceAreaId: value,
        },
      },
    });
  }

  changeStatusFilter(value, accountCandidatesStoreData) {
    return this.sortAndFilterData({
      ...accountCandidatesStoreData,
      meta: {
        ...accountCandidatesStoreData.meta,
        filters: {
          ...accountCandidatesStoreData.meta.filters,
          status: value,
        },
      },
    });
  }

  changeKeywordFilter(value, accountCandidatesStoreData) {
    return this.sortAndFilterData({
      ...accountCandidatesStoreData,
      meta: {
        ...accountCandidatesStoreData.meta,
        filters: {
          ...accountCandidatesStoreData.meta.filters,
          keyword: value,
        },
      },
    });
  }

  sortAndFilterData({ meta, originalData }) {
    let updatedData = [...originalData];

    if (meta.sort) {
      updatedData = updatedData.sort((a, b) => {
        if (a[meta.sort] === b[meta.sort]) {
          return 0;
        }

        if (a[meta.sort] === null) {
          return 1;
        }

        if (b[meta.sort] === null) {
          return -1;
        }

        return a[meta.sort] < b[meta.sort] ? 1 : -1;
      });
    }
    if (meta.filters.practiceAreaId) {
      updatedData = updatedData.filter(
        candidate =>
          candidate.practiceArea?.parentId === meta.filters.practiceAreaId ||
          candidate.practiceArea?.id === meta.filters.practiceAreaId
      );
    }

    if (meta.filters.status) {
      const showHidden = meta.filters.status === 'hidden';

      updatedData = updatedData.filter(
        candidate =>
          candidate.accountCandidateStatus === meta.filters.status ||
          (showHidden && candidate.isHiddenExternal)
      );
    }

    if (meta.filters.keyword) {
      updatedData = updatedData.filter(candidate =>
        candidate.searchableText.includes(meta.filters.keyword.toLowerCase())
      );
    }

    return this.setState({
      meta,
      originalData,
      data: updatedData,
      noFilterData: updatedData.length === 0,
    });
  }

  update(accountId, candidateId, practiceAreas, taxonomy, values) {
    return this.watchState(
      patchAccountsCandidates(accountId, candidateId, values)
    ).then(() => {
      this.load(accountId, practiceAreas, taxonomy);
    });
  }

  getDataShape() {
    return shape({
      meta: shape({
        sort: string,
        filters: shape({
          practiceAreaId: string,
          status: string,
          keyword: string,
        }),
      }),
      originalData: arrayOf(CandidateAccountShape),
      data: arrayOf(CandidateAccountShape),
      noFilterData: bool,
    });
  }
}

export const AccountCandidatesStore = new AccountCandidatesStoreClass();
