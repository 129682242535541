import React from 'react';
import { node, shape, string, number } from 'prop-types';
import { Text } from '@axiom/ui';

import { searchFiltersAllItemsShape } from '../../models/search-filters';

// These are in the order we want to display them.
const possibleCategories = [
  {
    name: 'United States',
    id: 'unitedStates',
  },
  {
    name: 'Canada',
    id: 'canada',
  },
  {
    name: 'Europe',
    id: 'europe',
  },
  {
    name: 'APAC',
    id: 'apac',
  },
  {
    name: 'Other',
    id: 'other',
  },
];

export const TalentSearchFiltersFormAdmissionsGroupings = ({
  data,
  children,
}) => {
  const childrenWithProps = key =>
    React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          data: data.items[key],
          groupingName: key,
        });
      }

      return child;
    });

  return possibleCategories.map(category => {
    const categoryData = data.items[category.id];

    return categoryData ? (
      <div key={category.id}>
        <Text bold size="small" name={`${category.name}_header`}>
          {category.name}
        </Text>
        {childrenWithProps(category.id)}
      </div>
    ) : null;
  });
};

TalentSearchFiltersFormAdmissionsGroupings.propTypes = {
  children: node.isRequired,
  data: shape({
    name: string.isRequired,
    selectedCount: number.isRequired,
    items: searchFiltersAllItemsShape,
  }).isRequired,
};
