import React from 'react';
import { Layout } from '@axiom/ui';

import DimaggioSrc from '../public/images/john-dimaggio.jpg';
import { PageLayout } from '../layouts/PageLayout/PageLayout';

export const DefaultPage = () => (
  <PageLayout showLoggedUser>
    <Layout position="center middle" horizontalGutter="16px">
      <strong>Welcomes to Base</strong>{' '}
      <img src={DimaggioSrc} alt="John DiMaggio" />
    </Layout>
  </PageLayout>
);
