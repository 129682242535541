import React, { useState } from 'react';
import {
  Card,
  CardSection,
  SmallHeader,
  Gutter,
  CondensedLarge,
  CondensedHeader,
  CardHeader,
  Button,
  Icon,
  CardFooter,
  Flashy,
  CandidateDescription,
  TalentExperiences,
  TalentEducation,
  TalentCertifications,
  TalentQualifications,
  TalentLanguages,
  useApi,
  CandidateHighlightExperienceSummary,
  CandidateHighlightExperienceDetails,
  ToastUtil,
  CandidateHighlightsUtil,
  CalendarEventsUtil,
  CalendarPermissionsUtil,
  CalendarTimeZoneUtil,
} from '@axiom/ui';
import {
  ClientRequestConst,
  CandidateOpportunitiesConst,
  ContactsOpportunitiesConst,
  AccountConst,
  PermissionImpersonationRoles,
} from '@axiom/const';
import {
  ClientRequest,
  CalendarEventType,
  AfcSubmission,
} from '@axiom/validation';
import { useNavigate, Link } from 'react-router-dom';
import { SubmissionCandidateModel } from '@axiom/ui-models';

import { AccountSubmissionsLegacyApi } from '../../api/account-submissions-legacy-api';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { AccountLegacyApi } from '../../api/account-legacy-api';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { CalendarApi } from '../../api/calendar-api';
import { PublicEngagementToLoginModal } from '../PublicEngagementToLoginModal/PublicEngagementToLoginModal';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { CandidateCardHeader } from '../CandidateCardHeader/CandidateCardHeader';
import { EngagementPrompt } from '../EngagementPrompt/EngagementPrompt';
import { InstantMatchesApi } from '../../api/instant-matches-api';
import { EngagementPassModal } from '../EngagementPassModal/EngagementPassModal';
import { TalentInterviewScheduleModal } from '../TalentInterviewScheduleModal/TalentInterviewScheduleModal';
import {
  TalentInterviewConfirmModal,
  InterviewContactsType,
} from '../TalentInterviewConfirmModal/TalentInterviewConfirmModal';
import {
  CandidateOpp,
  useEngagementTalentData,
} from '../../hooks/useEngagementTalentData';
import { TalentInterviewPromptModal } from '../TalentInterviewPromptModal/TalentInterviewPromptModal';

const showRequestMessage = (
  candidateStatus: CandidateOpp['candidateStatus']
) => {
  switch (candidateStatus) {
    case CandidateOpportunitiesConst.CandidateStatuses.Selected: {
      return (
        <CondensedLarge name="PENDO_REQUESTED_HIRE">
          <Gutter right="8px" as="span">
            Engagement Requested
          </Gutter>
          <Flashy color="dataVizGreen">
            <Icon name="circle-check-filled" />
          </Flashy>
        </CondensedLarge>
      );
    }
    default: {
      return null;
    }
  }
};

const showCtaButtons = (
  submission: AfcSubmission,
  candidateOpp: CandidateOpp
) => {
  return (
    submission.isQualificationComplete &&
    candidateOpp.candidateStatus !==
      CandidateOpportunitiesConst.CandidateStatuses.Engaged
  );
};

export const EngagementTalentDetails = () => {
  const navigate = useNavigate();
  const [displayLoginModal, setDisplayLoginModal] = useState(false);

  const { user, account } = useUserAccountData();
  const {
    accountId,
    candidateOpp,
    allCandidateOpps,
    submission,
    talentSlug,
    groupedExperiences,
  } = useEngagementTalentData();
  const [showPassModal, setShowPassModal] = useState(false);
  const [showEngageConfirmPrompt, setShowEngageConfirmPrompt] = useState(false);
  const [showInterviewAuto, setShowInterviewAuto] = useState(false);
  const [showInterviewConfirmModal, setShowInterviewConfirmModal] =
    useState(false);
  const [showInterviewPromptModal, setShowInterviewPromptModal] =
    useState(false);
  const [formattedPrompt, setFormattedPrompt] = useState('');

  const [{ data: calendarData } = { data: {} }] = useApi(
    candidateOpp?.candidate.calendar?.id &&
      CalendarApi.readCalendar(candidateOpp.candidate.calendar.id)
  );

  const getBaseForApiBody = () => ({
    submissionId: submission.submissionId,
    candidateId: candidateOpp.candidate.id,
    clientUserId: user?.id || null,
  });

  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const handleDataRefresh = () => {
    return Promise.all([
      AccountLegacyApi.refreshAccount(accountId, {
        expand: [AccountConst.Expansions.accountTeamUserInfo],
      }),
      AccountSubmissionsApi.refreshAccountSubmissionDetails(
        accountId,
        submission.submissionId
      ),
      AccountSubmissionsLegacyApi.refreshCandidateAccountSubmissionDetails(
        accountId,
        submission.submissionId,
        candidateOpp.candidate.id
      ),
    ]);
  };

  const submitInstantMatch = async () => {
    const submitCandidateBody = {
      opportunityId: submission.id,
      candidateId: candidateOpp?.candidate?.id,
      rate: candidateOpp.proposedHourlyRate,
    };

    await InstantMatchesApi.createSubmittedCandidateOppFromInstantMatch(
      submitCandidateBody
    );
  };

  const handlePassClick = () => {
    if (!user) {
      // If no user this must be the public view; send user to login
      setDisplayLoginModal(true);
      return;
    }

    setShowPassModal(!showPassModal);
  };

  const handleClosePassModal = () => {
    setShowPassModal(!showPassModal);
  };

  const handleAfterPassedOrInterviewNavigation = () => {
    const allCandidateIds = allCandidateOpps.map(
      (item: CandidateOpp) => item.candidate.id
    );

    const talentIndex = allCandidateIds.indexOf(candidateOpp.candidate.id);
    const nonPassedTalent = [...allCandidateIds];
    nonPassedTalent.splice(talentIndex, 1);

    let nextTalent: string | null = null;

    if (nonPassedTalent.length > 0) {
      nextTalent =
        talentIndex === allCandidateIds.length - 1
          ? allCandidateIds[talentIndex - 1]
          : allCandidateIds[talentIndex + 1];
    }

    if (nextTalent) {
      const to =
        talentIndex === 0 && !talentSlug
          ? `./${nextTalent}`
          : `../${nextTalent}`;
      navigate(to);
    }
  };

  const handleSubmitPassModal = (formData: {
    rejectionLossCode: string;
    notes?: string;
  }) => {
    if (candidateOpp.isInstantMatch) {
      const body = {
        candidateId: candidateOpp.candidate.id,
        opportunityId: submission.id,
      };

      handleAfterPassedOrInterviewNavigation();

      return InstantMatchesApi.updateInstantMatchPassed(body).then(async () => {
        await handleDataRefresh();
        await InstantMatchesApi.refreshInstantMatches(submission.submissionId);
        handleClosePassModal();
      });
    }

    const body: ClientRequest = {
      ...getBaseForApiBody(),
      requestType: ClientRequestConst.RequestTypes.ClientPassed,
      rejectionLossCodes: [formData.rejectionLossCode],
      notes: formData.notes,
    };

    handleAfterPassedOrInterviewNavigation();

    return AccountLegacyApi.updateClientRequest(accountId, body).then(
      async () => {
        await handleDataRefresh();
        await InstantMatchesApi.refreshInstantMatches(submission.submissionId);
        handleClosePassModal();
      }
    );
  };

  const handleInterviewRequestClick = () => {
    if (!user) {
      // If no user this must be the public view; send user to login
      setDisplayLoginModal(true);
      return;
    }

    const calendarPermissions = CalendarPermissionsUtil(
      user,
      PermissionImpersonationRoles.client
    );
    const hasFreeTime = calendarData?.events?.find(event => {
      return (
        !event.busy &&
        !CalendarEventsUtil.isInThePast(
          event.startTime,
          calendarPermissions.userPermissions()
        )
      );
    });

    if (hasFreeTime) {
      setShowInterviewAuto(true);
    } else {
      setShowInterviewPromptModal(true);
    }
  };

  const loadToast = () => {
    ToastUtil.add({
      name: `INTERVIEW_REQUESTED`,
      type: 'info',
      children: (
        <>
          <CondensedHeader>Interview Requested</CondensedHeader>
          <CondensedLarge>
            We have moved this match to the{' '}
            <Link
              data-test="TO_INTERVIEW_TAB"
              to={`${window.location.pathname.split('matches')[0]}interviews`}
            >
              Interviews tab
            </Link>{' '}
            and will confirm with everyone when the interview is set up.
          </CondensedLarge>
        </>
      ),
    });
  };

  const handleEngageRequestToggle = () => {
    if (!user) {
      // If no user this must be the public view; send user to login
      setDisplayLoginModal(true);
      return;
    }

    setShowEngageConfirmPrompt(!showEngageConfirmPrompt);
  };

  const refreshInstantMatchesWithDelay = () => {
    // Instant Matches are cleared by one-off-listener, this gives time for updates to complete
    return new Promise<void>(resolve => {
      setTimeout(async () => {
        await InstantMatchesApi.refreshInstantMatches(submission.submissionId);
        resolve();
      }, 1500);
    });
  };

  const handleEngageConfirm = async () => {
    if (candidateOpp.isInstantMatch) {
      await submitInstantMatch();
    }

    const body = {
      submissionId: submission.submissionId,
      candidateId: candidateOpp.candidate.id,
      clientUserId: user?.id || null,
      requestType: ClientRequestConst.RequestTypes.EngageRequested,
    };

    await AccountLegacyApi.updateClientRequest(accountId, body);
    setShowEngageConfirmPrompt(null);
    await handleDataRefresh();
    await refreshInstantMatchesWithDelay();
  };

  const confirmInterview = async (
    data?: InterviewContactsType
  ): Promise<unknown> => {
    const payload = {
      adhocAttendees: data.adHocContacts,
      contactIds: data.contacts,
      preferredVideoClient: data.isZoomNotPreferred
        ? null
        : AccountConst.PreferredVideoClient.zoom,
      candidateId: candidateOpp.candidate.id,
      submissionId: submission.submissionId,
      availabilityNote: data.availabilityNote,
    };

    if (candidateOpp.isInstantMatch) {
      await submitInstantMatch();
    }

    const res = await CalendarApi.createManualInterview(payload);
    await handleDataRefresh();
    await InstantMatchesApi.refreshInstantMatches(submission.submissionId);

    loadToast();
    setShowInterviewConfirmModal(false);
    setShowInterviewAuto(false);

    return res;
  };

  const scheduleInterview = async (
    data?: InterviewContactsType,
    scheduledEvent?: CalendarEventType
  ): Promise<unknown> => {
    const { title, startTime, endTime, busy } = scheduledEvent;
    const payload = {
      adhocAttendees: data.adHocContacts,
      contactIds: data.contacts,
      preferredVideoClient: data.isZoomNotPreferred
        ? null
        : AccountConst.PreferredVideoClient.zoom,
      candidateId: candidateOpp.candidate.id,
      submissionId: submission.submissionId,
      title,
      startTime,
      endTime,
      busy,
    };

    if (candidateOpp.isInstantMatch) {
      await submitInstantMatch();
    }

    const res = await CalendarApi.createScheduledCalendarInterview(payload);
    await handleDataRefresh();
    await InstantMatchesApi.refreshInstantMatches(submission.submissionId);

    loadToast();
    setShowInterviewAuto(false);

    return res;
  };

  const candidateDescription = new SubmissionCandidateModel(
    candidateOpp
  ).calculatedDescription();

  const primaryContactId = submission.contacts?.find(
    contact => contact.displayRole === ContactsOpportunitiesConst.Roles.Owner
  )?.id;

  return (
    <div data-test="talent-details">
      <Card>
        <CardHeader>
          <CandidateCardHeader
            candidateOpp={candidateOpp}
            onInterview={handleInterviewRequestClick}
            submission={submission}
          />
        </CardHeader>
        {!!candidateDescription && (
          <CardSection divider>
            <CandidateDescription
              description={candidateDescription}
              calculatedFirstName={candidateOpp.candidate.calculatedFirstName}
            />
          </CardSection>
        )}
        {showCtaButtons(submission, candidateOpp) && (
          <CardFooter stackableOn="mobile" name="CTA_FOOTER">
            {showRequestMessage(candidateOpp.candidateStatus) || (
              <>
                <Button
                  name="CLIENT_PASS"
                  pattern="primary"
                  variation="minimal"
                  onClick={handlePassClick}
                >
                  Pass
                </Button>
                <Button
                  name="ENGAGE"
                  pattern="primary"
                  variation="outline"
                  onClick={handleEngageRequestToggle}
                >
                  Engage
                </Button>
              </>
            )}
          </CardFooter>
        )}
      </Card>
      <Gutter bottom="16px" />
      {CandidateHighlightsUtil.calculateNumberOfHighlights(
        candidateOpp.candidate,
        submission
      ) > 0 && (
        <Card>
          <CardHeader>
            <SmallHeader>Highlights</SmallHeader>
          </CardHeader>
          <CardSection>
            <CandidateHighlightExperienceSummary
              candidate={candidateOpp.candidate}
              submission={submission}
            />
            <Gutter bottom="16px" />
            <CandidateHighlightExperienceDetails
              candidate={candidateOpp.candidate}
              practiceAreas={practiceAreas}
              taxonomy={taxonomy}
            />
          </CardSection>
        </Card>
      )}
      <Gutter bottom="16px" />
      {groupedExperiences.length > 0 && (
        <Gutter bottom="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Experience</SmallHeader>
            </CardHeader>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </Card>
        </Gutter>
      )}

      {candidateOpp.candidate.degrees?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="degrees">
            <CardHeader>
              <SmallHeader>Education</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentEducation candidate={candidateOpp.candidate} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {candidateOpp.candidate.certifications?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="certification">
            <CardHeader>
              <SmallHeader>Accomplishments</SmallHeader>
            </CardHeader>
            <CardSection divider>
              <TalentCertifications candidate={candidateOpp.candidate} />
            </CardSection>
            <CardSection>
              <TalentQualifications candidate={candidateOpp.candidate} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {candidateOpp.candidate.languages?.length > 0 && (
        <Gutter bottom="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Languages</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentLanguages candidate={candidateOpp.candidate} />
            </CardSection>
          </Card>
        </Gutter>
      )}

      {!!showPassModal && (
        <EngagementPassModal
          candidate={candidateOpp.candidate}
          closeModal={handleClosePassModal}
          submitModal={handleSubmitPassModal}
        />
      )}

      {!!showInterviewPromptModal && (
        <TalentInterviewPromptModal
          candidate={candidateOpp.candidate}
          onClose={() => setShowInterviewPromptModal(false)}
          onBack={() => {
            setShowInterviewPromptModal(false);
          }}
          onNext={(timezoneId, description) => {
            const t = CalendarTimeZoneUtil.getZoneFromId(timezoneId);
            setFormattedPrompt(`${t.abbr} - ${t.name}<br>${description}`);
            setShowInterviewPromptModal(false);
            setShowInterviewConfirmModal(true);
          }}
        />
      )}

      {!!showInterviewConfirmModal && (
        <TalentInterviewConfirmModal
          loggedInUser={user}
          submission={submission}
          candidate={candidateOpp.candidate}
          onClose={setShowInterviewConfirmModal}
          onConfirm={confirmInterview}
          primaryContactId={primaryContactId}
          account={account}
          availabilityNote={formattedPrompt}
        />
      )}

      {!!showInterviewAuto && (
        <TalentInterviewScheduleModal
          account={account}
          user={user}
          submission={submission}
          candidate={candidateOpp.candidate}
          calendarData={calendarData}
          onNoTimeAvailableFlowConfirm={confirmInterview}
          onConfirm={scheduleInterview}
          onClose={() => setShowInterviewAuto(false)}
          primaryContactId={primaryContactId}
        />
      )}
      {!!showEngageConfirmPrompt && (
        <EngagementPrompt
          candidateOpp={candidateOpp}
          onClose={handleEngageRequestToggle}
          onConfirm={handleEngageConfirm}
        />
      )}

      {!!displayLoginModal && (
        <PublicEngagementToLoginModal
          onClose={() => setDisplayLoginModal(false)}
        />
      )}
    </div>
  );
};
