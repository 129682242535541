import { AfcSubmission } from '@axiom/validation';
import { CandidateOpportunitiesConst } from '@axiom/const';

const { CandidateStatuses } = CandidateOpportunitiesConst;

const setData = (
  submission: AfcSubmission,
  isInterviewing = false
): AfcSubmission => {
  return {
    ...submission,
    positions: submission?.positions?.map(position => {
      return {
        ...position,
        candidateOpportunities: position.candidateOpportunities.filter(co => {
          if (isInterviewing) {
            return co.candidateStatus === CandidateStatuses.Interviewing;
          }
          return co.candidateStatus !== CandidateStatuses.Interviewing;
        }),
      };
    }),
  };
};

export const SubmissionsUtil = {
  getInterviewingCandidates: (
    submission?: AfcSubmission
  ): AfcSubmission | null => {
    if (!submission || Object.keys(submission).length === 0) return null;

    return setData(submission, true);
  },

  getNonInterviewingCandidates: (
    submission?: AfcSubmission
  ): AfcSubmission | null => {
    if (!submission || Object.keys(submission).length === 0) return null;

    return setData(submission, false);
  },
};
