import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useApi } from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AccountCandidatesStore } from '../../stores/account-candidates-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { CandidateAccountShape } from '../../models/candidate';
import { TaxonomyApi } from '../../api/taxonomy-api';

import { MyBenchItems } from './MyBenchItems';

const BenchCandidatesComponent = ({
  accountCandidatesState,
  account,
  practiceAreas,
  userState,
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());

  useEffect(() => {
    if (account.id) {
      AccountCandidatesStore.load(account.id, practiceAreas, taxonomy);
    }
  }, [account.id, practiceAreas, taxonomy]);

  return (
    <StoreStateTreatment
      allowOverflow
      name="BENCH_GRID"
      storeState={[accountCandidatesState, userState]}
      renderLoadedView={({ data: [accountCandidates, user] }) => (
        <MyBenchItems
          accountCandidates={accountCandidates}
          account={account}
          practiceAreas={practiceAreas}
          user={user}
        />
      )}
    />
  );
};

BenchCandidatesComponent.propTypes = {
  account: CandidateAccountShape.isRequired,
  accountCandidatesState: AccountCandidatesStore.getStateShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  userState: PreloadedUserStore.getStateShape().isRequired,
};

export const MyBench = connect(state => ({
  accountCandidatesState: AccountCandidatesStore.select(state),
  userState: PreloadedUserStore.select(state),
}))(BenchCandidatesComponent);
