import { useApi } from '@axiom/ui';
import { CandidateOpportunity, Account } from '@axiom/validation';

import { CandidateLegacyApi } from '../api/candidate-legacy-api';
import { CandidateApi } from '../api/candidate-api';

export const useCandidateProfilesData = (account: Account) => {
  const [{ data: savedCandidateOpps } = { data: [] }] = useApi(
    CandidateLegacyApi.getFavoriteCandidates()
  );

  const savedCandidates = savedCandidateOpps.map(c => c.candidate);
  const excludedCandidateIds = savedCandidateOpps?.map(
    (c: CandidateOpportunity) => c.candidateId
  );

  const [{ data: allCandidates } = { data: [] }] = useApi(
    account?.industryValue &&
      CandidateApi.readCandidateSearchResultsForDashboard({
        page: 1,
        pageSize: 11,
        filters: {
          industries: [account?.industryValue],
          excludedCandidateIds,
        },
      })
  );

  return {
    savedCandidates,
    allCandidates,
  };
};
