import React from 'react';
import {
  AfcSubmission,
  AfcSubmissionCandidate,
  AfcSubmissionCandidateOpportunity,
} from '@axiom/validation';
import {
  backgroundColorNames,
  Badge,
  Gutter,
  ParagraphHeader,
  TilesItem,
  Tiles,
  useBreakpoint,
  BadgeBackgroundType,
} from '@axiom/ui';
import { useNavigate } from 'react-router-dom';

import { EngagementTalentTile } from '../EngagementTalentTile/EngagementTalentTile';

export type ClientEngagementsEngagementTalentListProps = {
  hasMultipleRoles?: boolean;
  candidateOpportunities: AfcSubmissionCandidateOpportunity[];
  selectedCandidateId?: AfcSubmissionCandidate['id'];
  submission: AfcSubmission;
  title: string;
};

const BADGE_VALUES = {
  AXIOM_PICK: 'AXIOM PICK',
  SELF_SUBMITTED: 'SELF-SUBMITTED',
  TOP_MATCH: 'TOP MATCH',
};

const badgeMap = new Map([
  [BADGE_VALUES.AXIOM_PICK, backgroundColorNames.lightOrange],
  [BADGE_VALUES.SELF_SUBMITTED, backgroundColorNames.lightBlue],
  [BADGE_VALUES.TOP_MATCH, backgroundColorNames.orange],
]);

const setBadgeColor = (badge: string): BadgeBackgroundType | null => {
  if (!badge) return null;

  const key = badge.toUpperCase();

  if (badgeMap.has(key)) {
    return badgeMap.get(key);
  }

  return 'gray';
};

export const EngagementTalentList = ({
  hasMultipleRoles = false,
  candidateOpportunities,
  selectedCandidateId,
  submission,
  title,
}: ClientEngagementsEngagementTalentListProps) => {
  const navigate = useNavigate();
  const { isLargeScreen } = useBreakpoint();

  const showBadges = (candidateOpp: AfcSubmissionCandidateOpportunity) => {
    if (candidateOpp.badge) {
      return (
        <Badge
          flush="right"
          background={setBadgeColor(candidateOpp.badge)}
          name="badge"
        >
          {candidateOpp.badge}
        </Badge>
      );
    }

    if (candidateOpp.candidate.badges.length > 0) {
      return candidateOpp.candidate.badges.map(
        (badge: AfcSubmissionCandidate['badges'][number]) => {
          return (
            <Badge
              flush="right"
              background={setBadgeColor(badge)}
              name="badge"
              key={badge}
            >
              {badge}
            </Badge>
          );
        }
      );
    }

    return null;
  };

  return (
    <>
      {!!hasMultipleRoles && (
        <Gutter bottom="8px">
          <ParagraphHeader name="role-title">{title}</ParagraphHeader>
        </Gutter>
      )}
      <Gutter bottom="16px" name={`${title} item`}>
        <Tiles columnWidth={1} name="candidate-tiles">
          {candidateOpportunities.map(candidateOpp => {
            return (
              <TilesItem
                selected={candidateOpp.candidate.id === selectedCandidateId}
                key={candidateOpp.candidate.id}
                onClick={() => {
                  navigate(`./${candidateOpp.candidate.id}`);
                }}
                name={candidateOpp.candidate.id}
                Badge={showBadges(candidateOpp)}
              >
                <Gutter
                  top={isLargeScreen ? '8px' : null}
                  bottom="8px"
                  left="8px"
                >
                  <EngagementTalentTile
                    candidateOpp={candidateOpp}
                    submission={submission}
                  />
                </Gutter>
              </TilesItem>
            );
          })}
        </Tiles>
      </Gutter>
    </>
  );
};
