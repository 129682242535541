export const PublicEngagementUrlUtil = (path: string) => {
  return {
    toPublic: () => {
      const bits = path.split('/');

      bits[1] = bits[1] === 'my-axiom' ? 'engagement' : bits[1];
      return bits.join('/');
    },
    fromPublic: () => {
      const bits = path.split('/');

      bits[1] = bits[1] === 'engagement' ? 'my-axiom' : bits[1];
      return bits.join('/');
    },
    fromPublicMatchesToInterviews: () => {
      const bits = path.split('/');

      bits[1] = bits[1] === 'engagement' ? 'my-axiom' : bits[1];
      return bits.join('/').replace('matches', 'interviews');
    },
  };
};
