import React from 'react';
import { bool } from 'prop-types';
import {
  DataTestUtil,
  GridColumn,
  GridRow,
  Gutter,
  InfoIcon,
  Text,
  Visible,
} from '@axiom/ui';
import { Tooltip as KTooltip } from '@progress/kendo-react-tooltip';

import { Checkbox } from '../FormElements/Checkbox/Checkbox';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

import {
  CATPNIconContainer,
  CATPNColoredText,
  CATPNLabelWithIcon,
} from './CreateAccountTosPrivacyNoticeStyles';

export const CreateAccountTosPrivacyNotice = ({ user, withMakeAccount }) => {
  return user ? null : (
    <>
      {!!withMakeAccount && (
        <GridRow>
          <GridColumn>
            <Gutter bottom="2rem">
              <CATPNLabelWithIcon>
                <Checkbox
                  label="Create an Axiom account using my email address."
                  name="createAccount"
                />
                <Visible only="largeScreen">
                  <KTooltip
                    anchorElement="target"
                    position="right"
                    tooltipStyle={{
                      maxWidth: '16rem',
                      display: '',
                    }}
                  >
                    <CATPNIconContainer
                      className="iconContainer"
                      title="Creating an Axiom Account unlocks exclusive features like talent rates and priority response to requests when you're signed in."
                    >
                      <InfoIcon />
                    </CATPNIconContainer>
                  </KTooltip>
                </Visible>
              </CATPNLabelWithIcon>
            </Gutter>
          </GridColumn>
        </GridRow>
      )}

      <GridRow>
        <GridColumn>
          <Text size="small" subtle>
            By clicking on <strong>Agree and Submit</strong> you acknowledge
            that you have read and agree to the Axiom{' '}
            <CATPNColoredText>
              <a
                href="https://www.axiomlaw.com/axiom-terms-of-use"
                target="_blank"
                onClick={e => e.stopPropagation()}
                data-test={DataTestUtil.format('terms_of_use')}
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </CATPNColoredText>{' '}
            and{' '}
            <CATPNColoredText>
              <a
                href="https://www.axiomlaw.com/privacy-notice"
                target="_blank"
                onClick={e => e.stopPropagation()}
                data-test={DataTestUtil.format('privacy_notice')}
                rel="noreferrer"
              >
                Privacy Notice
              </a>
            </CATPNColoredText>
            .<CATPNColoredText>*</CATPNColoredText>
          </Text>
        </GridColumn>
      </GridRow>
    </>
  );
};

CreateAccountTosPrivacyNotice.propTypes = {
  user: PreloadedUserStore.getDataShape(),
  withMakeAccount: bool,
};

CreateAccountTosPrivacyNotice.defaultProps = {
  user: null,
  withMakeAccount: false,
};
