import React from 'react';
import {
  PossibleImageSizes,
  CountryCodes,
  DisplayPronouns,
} from '@axiom/const';
import { SubmissionCandidate } from '@axiom/validation';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { Layout } from '../../layout/Layout/Layout';
import { ImageCircle } from '../../element/ImageCircle/ImageCircle';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { CondensedMedium } from '../../content/CondensedMedium/CondensedMedium';
import { IconButton } from '../../element/Button/IconButton';
import { Gutter } from '../../layout/Gutter/Gutter';
import { CandidateBadges } from '../CandidateBadges/CandidateBadges';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { CandidateProfileUtil } from '../../../utils/candidate-profile-util';

export type ClientEngagementsCandidateHeaderTypes = {
  candidate: SubmissionCandidate;
  isAnonymous?: boolean;
  removeTalentImage?: boolean;
  removeLocationToggle?: boolean;
  onToggleLocation?: () => void;
  isBioView?: boolean;
};

export const ClientEngagementsCandidateHeader = ({
  candidate,
  isAnonymous = false,
  removeTalentImage = false,
  onToggleLocation,
  isBioView,
  removeLocationToggle = false,
}: ClientEngagementsCandidateHeaderTypes) => {
  const displayName = () => {
    if (candidate.calculatedDisplayName) {
      return candidate.calculatedDisplayName;
    }
    return 'Talent Name';
  };

  const renderLocationForBios = () => {
    if (candidate?.addressCountry === CountryCodes.US) {
      return [candidate?.addressState, candidate?.addressCountry]
        .filter(Boolean)
        .join(', ');
    }
    return candidate?.addressCountry;
  };

  return (
    <Layout position="middle" horizontalGutter="16px" name="CANDIDATE_HEADER">
      {!removeTalentImage && (
        <div>
          <ImageCircle
            name="CANDIDATE_IMAGE"
            imageName={displayName()}
            src={
              isAnonymous
                ? null
                : CandidateProfileUtil.getProfileImageUri(
                    candidate,
                    PossibleImageSizes.W_100
                  )
            }
          />
        </div>
      )}
      <div>
        <Layout position="middle" horizontalGutter="8px">
          <LayoutItem>
            <SmallHeader name="display-name">{displayName()}</SmallHeader>
          </LayoutItem>
          {!isAnonymous && candidate.pronouns && (
            <LayoutItem fluid>
              <Paragraph name="CANDIDATE_PRONOUNS">
                {DisplayPronouns[candidate.pronouns]}
              </Paragraph>
            </LayoutItem>
          )}
        </Layout>
        {!!removeTalentImage && !removeLocationToggle && (
          <CondensedMedium name="bio_location">
            <Layout position="middle">
              {renderLocationForBios()}
              {!removeLocationToggle && !!isBioView && !!onToggleLocation && (
                <IconButton
                  name="HIDE_LOCATION_CTA"
                  icon="x-mark"
                  variation="minimal"
                  onClick={() => onToggleLocation()}
                />
              )}
            </Layout>
          </CondensedMedium>
        )}
        {Number.isInteger(candidate.yearsOfExperience) &&
          candidate.yearsOfExperience >= 0 &&
          !removeTalentImage && (
            <CondensedMedium name="candidate-header">
              {`${new CandidateYearsOfExperienceModel(
                candidate.yearsOfExperience
              ).getYearsOfExperienceRange()} years experience`}
            </CondensedMedium>
          )}
        <Gutter bottom="8px" />
        <CandidateBadges badges={candidate.badges} />
      </div>
    </Layout>
  );
};
