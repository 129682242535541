import React, { useContext } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { LayoutItem } from '../Layout/LayoutItem';
import { Layout } from '../Layout/Layout';
import { IconButton } from '../../element/Button/IconButton';

import { AccordionContext } from './AccordionContext';

export type AccordionHeaderProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onToggle?: (expand: boolean) => void;
};
export const AccordionsItemHeader = ({
  children,
  className,
  disabled = false,
  name,
  onToggle,
}: AccordionHeaderProps) => {
  const { isExpanded, setIsExpanded } = useContext(AccordionContext);
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    e.stopPropagation();
    onToggle?.(!isExpanded);
    if (!disabled) {
      setIsExpanded(!isExpanded);
    }
  };
  return (
    <div
      className={AttrsHelper.formatClassname('accordion-header', className)}
      data-test={name}
      onClick={handleClick}
    >
      <Layout position="middle">
        <LayoutItem fluid>{children}</LayoutItem>

        <IconButton
          className="fixed-dimension-inline"
          name="expand-icon"
          pattern="secondary"
          variation="minimal"
          icon={isExpanded ? 'arrow-down' : 'arrow-right'}
          disabled={disabled}
          // @ts-expect-error Needs new type
          onClick={handleClick}
        />
      </Layout>
    </div>
  );
};
