import React, { SyntheticEvent } from 'react';
import qs from 'qs';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  useApi,
  Gutter,
  FormLabel,
  ContextMenu,
  ContextMenuItem,
  useBreakpoint,
  Icon,
  LayoutBleed,
  Input,
  Form,
  IconButton,
} from '@axiom/ui';

import { PracticeAreaApi } from '../../api/practice-areas-api';

import {
  SubNavigationVisibleLink,
  SearchInputWrapper,
} from './SubNavigationHeaderStyles';

const handleKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>,
  fireSubmit: (e?: SyntheticEvent<unknown, Event>) => void
) => {
  if (event.key.toLowerCase() === 'enter') {
    fireSubmit();
  }
};

export const SubNavigationHeader = () => {
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());
  const { isMobile, isLargeScreen, isTablet } = useBreakpoint();
  const navigate = useNavigate();

  const SearchSchema = z.object({
    SEARCH_ALT: z.string().nullable(),
  });

  const subNavLinks = [
    {
      name: 'Artificial Intelligence',
      link: { search: '"Artificial Intelligence"' },
    },
    {
      name: 'Capital Markets',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Capital Markets')?.id,
        ],
      },
    },
    {
      name: 'Commercial Contracts',
      link: { search: '"Commercial Contracts"' },
    },
    {
      name: 'Contract Management',
      link: { search: '"Contract Manager"' },
    },
    {
      name: 'Corporate Law',
      link: {
        practiceAreaId: [practiceAreas?.find(p => p.name === 'Corporate')?.id],
      },
    },
    {
      name: 'Data Privacy',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Data Privacy & Cybersecurity')
            ?.id,
        ],
      },
    },
    {
      name: 'Finance & Banking',
      link: {
        practiceAreaId: [practiceAreas?.find(p => p.name === 'Finance')?.id],
      },
    },
    {
      name: 'Insurance',
      link: {
        practiceAreaId: [practiceAreas?.find(p => p.name === 'Insurance')?.id],
      },
    },
    {
      name: 'Intellectual Property',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Intellectual Property')?.id,
        ],
      },
    },
    {
      name: 'Labor & Employment',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Labor & Employment')?.id,
        ],
      },
    },
    {
      name: 'Legal Operations',
      link: { search: '"Legal Operations"' },
    },
    {
      name: 'Litigation',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Litigation & Dispute Resolution')
            ?.id,
        ],
      },
    },
    {
      name: 'Marketing Law',
      link: {
        search: '"Advertising, Marketing & Promotional Review"',
      },
    },
    {
      name: 'Mergers & Acquisitions',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Mergers & Acquisitions')?.id,
        ],
      },
    },
    {
      name: 'Paralegal',
      link: { search: '"Paralegal"' },
    },
    {
      name: 'Real Estate',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Real Estate')?.id,
        ],
      },
    },
    {
      name: 'Regulatory & Compliance',
      link: {
        practiceAreaId: [
          practiceAreas?.find(p => p.name === 'Regulatory & Compliance')?.id,
        ],
      },
    },
    {
      name: 'Technology Licensing',
      link: { search: '"Cloud/SaaS/Software Licensing"' },
    },
  ];

  const navigateToALTPage = (link: unknown) => {
    navigate(
      `/access-legal-talent/page/1${qs.stringify(link, {
        addQueryPrefix: true,
      })}`
    );
  };

  const handleSubmitSearch = (formData: z.infer<typeof SearchSchema>) => {
    if (!formData.SEARCH_ALT) {
      return;
    }
    navigateToALTPage({ search: `"${formData.SEARCH_ALT}"` });
  };

  return (
    <Form
      schema={SearchSchema}
      name="SUB_NAVIGATION_FORM"
      initialValues={{ SEARCH_ALT: '' }}
      onSubmit={formData => handleSubmitSearch(formData)}
    >
      {({ fireSubmit }) => {
        return (
          <Layout
            background="panelLight"
            name="SUB_NAVIGATION"
            borderBottom="1px solid contentBreak"
          >
            <LayoutBleed>
              <Gutter vertical="16px">
                {!!isLargeScreen && (
                  <Layout position="space-between middle">
                    <Layout
                      horizontalGutter="16px"
                      position="middle"
                      name="LARGE_SCREEN_LINKS"
                    >
                      <FormLabel>Explore Practice Areas:</FormLabel>
                      {subNavLinks.slice(0, 4).map((item, index) => (
                        <SubNavigationVisibleLink
                          data-test={`${item.name}_LINK`}
                          key={`${item.name}_${index + 1}`}
                          onClick={() => navigateToALTPage(item.link)}
                        >
                          {item.name}
                        </SubNavigationVisibleLink>
                      ))}
                      <ContextMenu
                        name="LARGE_SCREEN_CONTEXT_MENU"
                        anchor={
                          <>
                            <Gutter right="8px">More</Gutter>
                            <Icon name="arrow-down" />
                          </>
                        }
                      >
                        {subNavLinks.slice(4).map((item, index) => (
                          <ContextMenuItem
                            name={`${item.name}_LINK`}
                            key={`${item.name}_${index + 1}`}
                            onClick={() => navigateToALTPage(item.link)}
                          >
                            {item.name}
                          </ContextMenuItem>
                        ))}
                      </ContextMenu>
                    </Layout>
                    <SearchInputWrapper
                      onKeyDown={e => handleKeyDown(e, fireSubmit)}
                    >
                      <Input placeholder="Talent Search" name="SEARCH_ALT" />
                    </SearchInputWrapper>
                    <Gutter horizontal="8px">
                      <IconButton
                        name="SEARCH_BUTTON"
                        icon="search"
                        onClick={fireSubmit}
                      />
                    </Gutter>
                  </Layout>
                )}
                {!!isTablet && (
                  <Layout position="space-between">
                    <Layout
                      horizontalGutter="16px"
                      position="middle"
                      name="TABLET_SCREEN_LINKS"
                    >
                      {subNavLinks.slice(0, 2).map((item, index) => (
                        <SubNavigationVisibleLink
                          data-test={`${item.name}_LINK`}
                          key={`${item.name}_${index + 1}`}
                          onClick={() => navigateToALTPage(item.link)}
                        >
                          {item.name}
                        </SubNavigationVisibleLink>
                      ))}
                      <ContextMenu
                        name="TABLET_SCREEN_CONTEXT_MENU"
                        anchor={
                          <>
                            <Gutter right="8px">More</Gutter>
                            <Icon name="arrow-down" />
                          </>
                        }
                      >
                        {subNavLinks.slice(2).map((item, index) => (
                          <ContextMenuItem
                            name={`${item.name}_LINK`}
                            key={`${item.name}_${index + 1}`}
                            onClick={() => navigateToALTPage(item.link)}
                          >
                            {item.name}
                          </ContextMenuItem>
                        ))}
                      </ContextMenu>
                    </Layout>
                    <SearchInputWrapper
                      onKeyDown={e => handleKeyDown(e, fireSubmit)}
                    >
                      <Input placeholder="Talent Search" name="SEARCH_ALT" />
                    </SearchInputWrapper>
                    <Gutter horizontal="8px">
                      <IconButton
                        name="SEARCH_BUTTON"
                        icon="search"
                        onClick={fireSubmit}
                      />
                    </Gutter>
                  </Layout>
                )}
                {!!isMobile && (
                  <Layout
                    horizontalGutter="16px"
                    position="middle"
                    name="MOBILE_SCREEN_LINKS"
                  >
                    <FormLabel>Explore Practice Areas:</FormLabel>
                    <ContextMenu
                      name="MOBILE_SCREEN_CONTEXT_MENU"
                      direction="left"
                      anchor={
                        <>
                          <Gutter right="8px">All</Gutter>
                          <Icon name="arrow-down" />
                        </>
                      }
                    >
                      {subNavLinks.map((item, index) => (
                        <ContextMenuItem
                          name={`${item.name}_LINK`}
                          key={`${item.name}_${index + 1}`}
                          onClick={() => navigateToALTPage(item.link)}
                        >
                          {item.name}
                        </ContextMenuItem>
                      ))}
                    </ContextMenu>
                  </Layout>
                )}
              </Gutter>
            </LayoutBleed>
          </Layout>
        );
      }}
    </Form>
  );
};
