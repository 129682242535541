import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';
import { Account } from '@axiom/validation';
import { OpportunitiesConst } from '@axiom/const';

import { AccountSubmissionsLegacyApi } from '../api/account-submissions-legacy-api';

interface Args {
  account?: Account;
}

export const useSubmissionsData = (props: Args = {}) => {
  const params = useParams();
  const urlAccountId = params?.accountId;
  const accountId = props?.account?.id ?? urlAccountId;
  const [{ data: allSubmissions } = { data: [] }] = useApi(
    accountId && AccountSubmissionsLegacyApi.readAccountSubmissions(accountId)
  );
  const allActiveSubmissions = allSubmissions.filter(
    submission =>
      submission.projectState === OpportunitiesConst.ProjectStates.Active
  );
  const allUpcomingSubmissions = allSubmissions.filter(
    submission =>
      submission.projectState === OpportunitiesConst.ProjectStates.Submitted
  );
  return {
    allSubmissions,
    allActiveSubmissions,
    allUpcomingSubmissions,
  };
};
