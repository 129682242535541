import React from 'react';
import { string } from 'prop-types';
import { Button, CondensedMedium, Gutter, PageHeader } from '@axiom/ui';

import { PageLayout } from '../PageLayout/PageLayout';

export const ErrorBoundaryLayout = ({ errorMessage }) => (
  <PageLayout>
    {!!errorMessage && (
      <PageHeader>{!!errorMessage && <>{errorMessage}!</>}</PageHeader>
    )}
    <Gutter bottom="16px" />
    <CondensedMedium>
      There was a problem displaying this page. We apologize for any
      inconvenience. Please try again, and if the issue continues, reach out to
      your Axiom contact for assistance.
    </CondensedMedium>
    <Gutter bottom="16px" />
    <Button
      onClick={() => {
        window.document.location.href = '/';
      }}
    >
      Home
    </Button>
  </PageLayout>
);

ErrorBoundaryLayout.propTypes = {
  errorMessage: string,
};
ErrorBoundaryLayout.defaultProps = {
  errorMessage: null,
};
