import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Gutter, SearchIcon, FilterIcon, Label, Visible } from '@axiom/ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CandidateOpportunitiesConst } from '@axiom/const';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FormDigitalBenchSortFilterStore } from '../../stores/form-digital-bench-sort-filter-store';
import { AccountCandidatesStore } from '../../stores/account-candidates-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { isEnvoyUser as getIsEnvoyUser } from '../../utils/roles-util';
import { EXCLUDED_PRACTICE_AREAS } from '../../utils/talent-search-filter-util';
import { getTreeTrunk } from '../../utils/dropdown-tree-util';

import {
  BCFilterWrapper,
  BCKeywordInput,
  BCKeywordWrapper,
  BCTransparentSingleSelect,
  BCSortFiltersContainer,
  BCSortWrapper,
} from './MyBenchStyles';

const { Engaged, Completed } = CandidateOpportunitiesConst.CandidateStatuses;

export const MyBenchSortFilters = ({
  accountCandidates,
  practiceAreas,
  user,
}) => {
  const [showFilters, setShowFilters] = useState(true);
  const { i18n } = useLingui();
  const isEnvoyUser = getIsEnvoyUser(user);

  const sortOptions = [
    { label: i18n._(t`Default`), value: '' },
    { label: i18n._(t`Additional availability`), value: 'weeklyAvailability' },
    { label: i18n._(t`Years of experience`), value: 'yearsOfExperience' },
  ];

  const practiceAreaOptions = [
    { label: i18n._(t`All`), value: '' },
    ...getTreeTrunk(
      practiceAreas.filter(
        ({ name }) => !EXCLUDED_PRACTICE_AREAS.includes(name)
      ),
      true
    ),
  ];

  const statusOptions = [
    { label: i18n._(t`All`), value: '' },
    { label: i18n._(t`Currently engaged`), value: Engaged },
    { label: i18n._(t`Previously engaged`), value: Completed },
  ];

  if (isEnvoyUser) {
    statusOptions.push({
      label: i18n._(t`Hidden`),
      value: 'hidden',
    });
  }

  useEffect(() => {
    FormDigitalBenchSortFilterStore.load(accountCandidates.meta);
  }, [accountCandidates.meta]);

  const handleKeywordSearch = debounce(keyword => {
    AccountCandidatesStore.changeKeywordFilter(keyword, accountCandidates);
  }, 200);

  return (
    <FormStateTreatment
      name="BENCH_CANDIDATES_SORT_FILTER_FORM_TREATMENT"
      formStore={FormDigitalBenchSortFilterStore}
      renderLoadedView={() => (
        <Gutter bottom="25px">
          <BCSortFiltersContainer>
            {!!showFilters && (
              <>
                <BCFilterWrapper>
                  <BCTransparentSingleSelect
                    name="practiceAreaId"
                    label={i18n._(t`Filter by practice area`)}
                    onChange={practiceAreaId => {
                      AccountCandidatesStore.changePracticeAreaFilter(
                        practiceAreaId,
                        accountCandidates
                      );
                    }}
                    options={practiceAreaOptions}
                  />
                </BCFilterWrapper>
                <BCFilterWrapper>
                  <BCTransparentSingleSelect
                    name="status"
                    label={i18n._(t`Filter by status`)}
                    onChange={status => {
                      AccountCandidatesStore.changeStatusFilter(
                        status,
                        accountCandidates
                      );
                    }}
                    options={statusOptions}
                  />
                </BCFilterWrapper>
                <BCKeywordWrapper>
                  <div>
                    <BCKeywordInput
                      name="keyword"
                      data-test="KEYWORD_INPUT"
                      label={
                        <Label>
                          <Trans>Filter by keyword</Trans>
                        </Label>
                      }
                      onChange={keyword => {
                        handleKeywordSearch(keyword, accountCandidates);
                      }}
                      placeholder={i18n._(t`Search`)}
                    />
                  </div>
                  <SearchIcon height={17} width={17} />
                </BCKeywordWrapper>
              </>
            )}
            <BCSortWrapper>
              <BCTransparentSingleSelect
                name="sort"
                label={i18n._(t`Sort by`)}
                options={sortOptions}
                onChange={sort =>
                  AccountCandidatesStore.changeSort(sort, accountCandidates)
                }
              />
            </BCSortWrapper>
            <Visible only="smallScreen">
              <div className="text-align-right">
                <a
                  className="float-right"
                  data-test="TOGGLE_FILTERS_BUTTON"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <FilterIcon />
                  {showFilters ? (
                    <Trans>Hide filters</Trans>
                  ) : (
                    <Trans>Show filters</Trans>
                  )}
                </a>
              </div>
            </Visible>
          </BCSortFiltersContainer>
        </Gutter>
      )}
    />
  );
};

MyBenchSortFilters.propTypes = {
  accountCandidates: AccountCandidatesStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};
