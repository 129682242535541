import React from 'react';
import { string } from 'prop-types';
import { DataTest } from '@axiom/ui';

import { PreloadedUserStore } from '../../stores/preloaded-user-store';

export const InquireWithAxiomModalTooltip = ({ user, title }) =>
  user ? (
    <DataTest value={`${title}_CLIENT_TOOLTIP_TEXT`}>
      Need to change this name or email? To make a 1-time change, sign out
      before you inquire again. To update your account, reach out to our{' '}
      <a href="mailto:DSS@axiomlaw.com" target="blank">
        Digital Sales Support Team.
      </a>
    </DataTest>
  ) : null;

InquireWithAxiomModalTooltip.propTypes = {
  title: string.isRequired,
  user: PreloadedUserStore.getDataShape(),
};

InquireWithAxiomModalTooltip.defaultProps = {
  user: null,
};
