import React from 'react';
import { shape, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/macro';
import {
  Header3,
  Grid,
  GridRow,
  GridColumn,
  Gutter,
  Header5,
  MediaQueryUtil,
} from '@axiom/ui';

import { ProfileImage } from '../ProfileImage/ProfileImage';
import { PALETTE } from '../../themes/constants';

const AxiomTeamWrapper = styled.div`
  a {
    text-transform: uppercase;
    color: ${PALETTE.grays.gray9};
    border-bottom: 3px solid ${PALETTE.colors.axiomOrange};
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: ${PALETTE.colors.axiomOrange};
      border-bottom: 3px solid ${PALETTE.grays.gray9};
    }
  }

  ${MediaQueryUtil.mobileOnly(css`
    .contact-name {
      margin-bottom: 0.5rem;
    }
  `)}
`;

const fullName = (f, l) => `${f} ${l}`;

export const AxiomTeam = ({ ownerUser, cxPartner, accountName }) => {
  if (!ownerUser && !cxPartner) {
    return null;
  }

  return (
    <AxiomTeamWrapper data-test="AXIOM_TEAM">
      <Header5 subtle uppercase>
        <Trans>Axiom Team</Trans>
      </Header5>
      {!!ownerUser && (
        <Gutter gutterType="margin" top="2rem">
          <Grid name="OWNER_USER">
            <GridRow verticalAlign="middle">
              <GridColumn width={3}>
                <ProfileImage
                  size="82px"
                  fullName={fullName(ownerUser.firstName, ownerUser.lastName)}
                />
              </GridColumn>
              <GridColumn width={9}>
                <Header3 accent name="OWNER_FULL_NAME" className="contact-name">
                  {fullName(ownerUser.firstName, ownerUser.lastName)}
                </Header3>
                <Header5 subtle>
                  <Trans>Relationship Manager</Trans>
                </Header5>
                <a
                  data-test="OWNER_CONTACT_LINK"
                  href={`mailto:${ownerUser.email}?subject=Axiom for Clients Support Request from ${accountName}`}
                >
                  <Trans>Contact</Trans>
                </a>
              </GridColumn>
            </GridRow>
          </Grid>
        </Gutter>
      )}
      {!!cxPartner && (
        <Gutter gutterType="margin" top="3.125rem">
          <Grid name="CX_PARTNER">
            <GridRow verticalAlign="middle">
              <GridColumn width={3}>
                <ProfileImage
                  size="82px"
                  fullName={fullName(cxPartner.firstName, cxPartner.lastName)}
                />
              </GridColumn>
              <GridColumn width={9}>
                <Header3 accent name="CX_FULL_NAME" className="contact-name">
                  {fullName(cxPartner.firstName, cxPartner.lastName)}
                </Header3>
                <Header5 subtle>
                  <Trans>Account Manager</Trans>
                </Header5>
                <a
                  data-test="CX_CONTACT_LINK"
                  href={`mailto:${cxPartner.email}?subject=Axiom for Clients Support Request from ${accountName}`}
                >
                  <Trans>Contact</Trans>
                </a>
              </GridColumn>
            </GridRow>
          </Grid>
        </Gutter>
      )}
    </AxiomTeamWrapper>
  );
};

AxiomTeam.defaultProps = {
  accountName: null,
  ownerUser: null,
  cxPartner: null,
};

AxiomTeam.propTypes = {
  accountName: string,
  cxPartner: shape({ firstName: string, lastName: string, email: string }),
  ownerUser: shape({
    firstName: string,
    lastName: string,
    email: string,
  }),
};
