import React from 'react';
import {
  PossibleImageSizes,
  CountryCodes,
  CandidateOpportunitiesConst,
  DisplayPronouns,
} from '@axiom/const';
import {
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
} from '@axiom/validation';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';
import {
  Button,
  CandidateBadges,
  CandidateProfileUtil,
  CondensedMedium,
  Gutter,
  ImageCircle,
  Layout,
  LayoutItem,
  Paragraph,
  SmallHeader,
  useBreakpoint,
} from '@axiom/ui';

export type CandidateCardHeaderTypes = {
  candidateOpp: AfcSubmissionCandidateOpportunity;
  hideTalentImage?: boolean;
  submission?: AfcSubmission;
  onEngage?: () => void;
  onPass?: () => void;
  onInterview?: () => void;
};

export const CandidateCardHeader = ({
  candidateOpp,
  hideTalentImage = false,
  submission,
  onEngage,
  onPass,
  onInterview,
}: CandidateCardHeaderTypes) => {
  const { isMobile } = useBreakpoint();
  const { candidate } = candidateOpp;
  const formatLocationForBios = () => {
    if (candidate.addressCountry === CountryCodes.US) {
      return [candidate.addressState, candidate.addressCountry]
        .filter(Boolean)
        .join(', ');
    }
    return candidate.addressCountry;
  };

  const showCtaButtons = () => {
    return (
      submission?.isQualificationComplete &&
      candidateOpp.candidateStatus !==
        CandidateOpportunitiesConst.CandidateStatuses.Engaged &&
      candidateOpp.candidateStatus !==
        CandidateOpportunitiesConst.CandidateStatuses.Selected
    );
  };

  return (
    <Layout
      position={isMobile ? 'left' : 'middle'}
      horizontalGutter="16px"
      name="CANDIDATE_HEADER"
      direction={isMobile ? 'vertical' : 'horizontal'}
    >
      {!hideTalentImage && (
        <LayoutItem>
          <ImageCircle
            name="CANDIDATE_IMAGE"
            imageName={candidate.calculatedDisplayName}
            src={CandidateProfileUtil.getProfileImageUri(
              candidate,
              PossibleImageSizes.W_100
            )}
          />
          <Gutter bottom="16px" only="mobile" />
        </LayoutItem>
      )}
      <LayoutItem fluid>
        <Layout position="middle" horizontalGutter="8px">
          <LayoutItem>
            <SmallHeader name="display-name">
              {candidate.calculatedDisplayName}
            </SmallHeader>
          </LayoutItem>
          <LayoutItem fluid>
            {candidate.pronouns && (
              <Paragraph name="CANDIDATE_PRONOUNS">
                {DisplayPronouns[candidate.pronouns]}
              </Paragraph>
            )}
          </LayoutItem>
        </Layout>
        {!!hideTalentImage && (
          <CondensedMedium name="bio_location">
            <Layout position="middle">{formatLocationForBios()}</Layout>
          </CondensedMedium>
        )}
        {Number.isInteger(candidate.yearsOfExperience) &&
          candidate.yearsOfExperience >= 0 &&
          !hideTalentImage && (
            <CondensedMedium name="candidate-header">
              {`${new CandidateYearsOfExperienceModel(
                candidate.yearsOfExperience
              ).getYearsOfExperienceRange()} years experience`}
            </CondensedMedium>
          )}
        <Gutter bottom="8px" />
        <CandidateBadges badges={candidate.badges} />
        <Gutter bottom="16px" only="mobile" />
      </LayoutItem>
      {showCtaButtons() && (
        <>
          {!!onInterview && (
            <LayoutItem fluid={isMobile} position="center">
              <Button
                fluid={isMobile}
                onClick={() => onInterview()}
                name="PENDO_REQUEST_INTERVIEW_CTA"
              >
                Interview
              </Button>
              <Gutter bottom="16px" only="mobile" />
            </LayoutItem>
          )}
          {!!onPass && (
            <LayoutItem fluid={isMobile} position="center">
              <Button
                variation="outline"
                fluid={isMobile}
                onClick={() => onPass()}
                name="PASS_BUTTON"
              >
                Pass
              </Button>
              <Gutter bottom="16px" only="mobile" />
            </LayoutItem>
          )}
          {!!onEngage && (
            <LayoutItem fluid={isMobile} position="center">
              <Button
                fluid={isMobile}
                onClick={() => onEngage()}
                name="ENGAGE_BUTTON"
              >
                Engage
              </Button>
            </LayoutItem>
          )}
        </>
      )}
    </Layout>
  );
};
